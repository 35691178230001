import { css } from '@emotion/react';
import { rcConfig } from '../rcomps';
import { colors } from './colors';
import { dims } from './dims';
import { ISize, ISizes } from './types';

class Toolbar {
  constructor(
    private size: ISizes = {
      nav: { min: 10, max: 100, cap: 50, vw: 5 },
      icon: { min: 10, max: 100, cap: 50, vw: 5 },
      hgap: { min: 6, max: 30, cap: 28, vw: 3 },
    },
    public color = colors.transparent(0.2),
    public hoverColor = colors.transparent(0.05),
  ) {}

  private clamp = (d: ISize, sf = 1) => `clamp(${d.min}px, ${d.vw * sf}vw, ${d.max}px)`;

  variables = (fullView: boolean) =>
    fullView
      ? ` --nav: ${this.clamp(this.size.nav)};
          --icon: ${this.clamp(this.size.icon)};
          --hgap: ${this.clamp(this.size.hgap)};
          --vgap: calc(var(--hgap) / ${dims.aspectRatio});
        `
      : ` --nav: ${this.clamp(this.size.nav)};
          --icon: ${this.clamp(this.size.icon)};
          --hgap: ${this.clamp(this.size.hgap)};
          --vgap: calc(var(--hgap) / ${dims.aspectRatio});

          @media screen and (min-width: ${rcConfig.media.phone.maxWidth}px) {
            --nav: ${this.clamp(this.size.nav, 0.8)};
            --icon: ${this.clamp(this.size.icon, 0.8)};
            --hgap: ${this.clamp(this.size.hgap, 0.8)};
            --vgap: calc(var(--hgap) / ${dims.aspectRatio});
          }

          @media screen and (min-width: ${dims.minMaxPageWidth}px) {
            --nav: ${this.size.nav.cap}px;
            --icon: ${this.size.icon.cap}px;
            --hgap: ${this.size.hgap.cap}px;
            --vgap: calc(var(--hgap) / ${dims.aspectRatio});
          }
        `;

  cssTopLeft = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: var(--vgap);
    left: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssTopRight = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: var(--vgap);
    right: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssTopRight1 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: var(--vgap);
    right: calc(var(--hgap) + var(--icon) + var(--hgap) * 0.5);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssTopRight2 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: var(--vgap);
    right: calc(var(--hgap) + var(--icon) * 2 + var(--hgap));
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssBottomLeft = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    bottom: var(--vgap);
    left: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssBottomRight = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    bottom: var(--vgap);
    right: var(--hgap);
    width: var(--nav);
    height: var(--nav);
    z-index: ${zIndex};
  `;

  cssBottomRight1 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    bottom: var(--vgap);
    right: calc(var(--hgap) + var(--icon) + var(--hgap) * 0.5);
    width: var(--nav);
    height: var(--nav);
    z-index: ${zIndex};
  `;

  cssRight1 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 2 + var(--icon));
    right: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssRight2 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 3 + var(--icon) * 2);
    right: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssRight3 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 4 + var(--icon) * 3);
    right: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssRight4 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 5 + var(--icon) * 4);
    right: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssLeft1 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 2 + var(--icon));
    left: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssLeft2 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 3 + var(--icon) * 2);
    left: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssLeft3 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 4 + var(--icon) * 3);
    left: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssLeft4 = (zIndex = 3) => css`
    line-height: 0;
    position: absolute;
    top: calc(var(--vgap) * 5 + var(--icon) * 4);
    left: var(--hgap);
    width: var(--icon);
    height: var(--icon);
    z-index: ${zIndex};
  `;

  cssBottomMiddle = () => css`
    position: absolute;
    bottom: var(--vgap);
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(var(--icon) * 0.4);
    color: ${this.color};
  `;
}

export const toolbar = new Toolbar();
