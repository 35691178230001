/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconMenu } from '@cpmech/iricons/IconMenu';
import { useMediaQuery } from 'react-responsive';
import { withUseGateObserver } from '../gate';
import {
  RcButton,
  rcConfig,
  rcDefaultMenuOptions,
  RcLinkOrDiv,
  RcMenuEntry,
  RcMenuHoriz,
} from '../rcomps';
import { SearchBar } from '../components';
import { styles } from '../styles';
import { control, gate } from '../service';
import { useControl, useNav } from '../hooks';

const buttonProps = {
  borderRadius: styles.dims.input.borderRadius,
  height: styles.dims.input.height,
  outline: true,
  color: styles.colors.white(),
  hoverColor: styles.colors.grey(50),
  hoverColorOutline: 'rgba(0,0,0,0.3)',
};

const useGateObserver = withUseGateObserver(gate);

export interface HeaderProps {
  withMenuButton?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ withMenuButton = true }) => {
  const gateStatus = useGateObserver('Header');
  const isNarrow = useMediaQuery({ maxWidth: rcConfig.media.phone.maxWidth });
  const isSmall = useMediaQuery({ maxWidth: 700 });
  const { goto, gohome } = useNav();
  const { showSideBar } = useControl('Header');

  const menuEntries: RcMenuEntry[] = [];

  if (!showSideBar) {
    menuEntries.push({
      comp: (
        <RcLinkOrDiv onClick={() => control.setShowSideBar(true)} color={styles.colors.white()}>
          <IconMenu size={styles.dims.icon.large} />
        </RcLinkOrDiv>
      ),
    });
  }

  if (withMenuButton) {
    menuEntries.push({
      comp: (
        <RcLinkOrDiv onClick={() => control.setShowLeftMenu(true)} color={styles.colors.white()}>
          <IconMenu size={styles.dims.icon.large} />
        </RcLinkOrDiv>
      ),
    });
  }

  menuEntries.push({
    comp: (
      <RcLinkOrDiv
        onClick={gohome}
        color={styles.colors.white()}
        hoverColor={styles.colors.blue(50)}
      >
        <h2>{isSmall ? 'DP' : 'Dorival Pedroso'}</h2>
      </RcLinkOrDiv>
    ),
  });

  if (!isNarrow) {
    menuEntries.push({ comp: <SearchBar /> });
  }

  if (gateStatus.hasAccess) {
    menuEntries.push({
      comp: (
        <RcButton
          onClick={() => {
            gate.signOut();
            goto({ route: 'goodbye' });
          }}
          {...buttonProps}
        >
          SIGN OUT
        </RcButton>
      ),
    });
  } else {
    menuEntries.push({
      comp: (
        <RcButton onClick={() => goto({ route: 'signin' })} {...buttonProps}>
          ACCESS
        </RcButton>
      ),
    });
  }

  return (
    <div
      css={css`
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;

        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      `}
    >
      <RcMenuHoriz
        entries={menuEntries}
        height={styles.dims.header.height}
        marginTop={'0px'}
        paddingVert={'0px'}
        options={rcDefaultMenuOptions({
          colorLabel: styles.colors.white(),
          paddingHoriz: '20px',
          maxWidth: `${styles.dims.minMaxPageWidth}px`,
        })}
      />
    </div>
  );
};
