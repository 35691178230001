/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, useLocation } from 'wouter';
import { control } from '../service';
import { styles } from '../styles';

export interface MenuLinkProps {
  onMenu: boolean;
  label: string;
  route: string;
  fontSize?: string;
  matchSlideIndex?: boolean;
}

export const MenuLink: React.FC<MenuLinkProps> = ({
  onMenu,
  label,
  route,
  fontSize,
  matchSlideIndex = false,
}) => {
  const [location] = useLocation();

  let isMatch = location === route;

  if (!matchSlideIndex) {
    const l = location.split('/').slice(1);
    const r = route.split('/').slice(1);
    if (l.length > 2) {
      l.pop();
    }
    if (r.length > 2) {
      r.pop();
    }
    isMatch = l.join('/') === r.join('/');
  }

  if (isMatch) {
    return (
      <div
        css={css`
          color: ${onMenu ? styles.colors.orange() : styles.colors.grey()};
          ${fontSize ? `font-size: ${fontSize};` : ''}
        `}
      >
        {label}
      </div>
    );
  }

  return (
    <Link
      href={route}
      onClick={() => control.setShowLeftMenu(false)}
      css={css`
        text-decoration: none;
        ${fontSize ? `font-size: ${fontSize};` : ''}
        color: ${onMenu ? styles.colors.white() : styles.colors.blue()};
        ${onMenu
          ? `:hover { color: ${styles.colors.grey(50)}; text-decoration: underline; }`
          : `:hover { color: ${styles.colors.blue(50)}; text-decoration: underline; }`};
      `}
    >
      {label}
    </Link>
  );
};
