import { GateStore, gateLocale, LocalGateStore, IStorage } from '../gate';
import { config } from './config';

gateLocale.setLocale('en');

const storage: IStorage = {
  getItem: async (key: string) => window.localStorage.getItem(key),
  setItem: async (key: string, value: string) => window.localStorage.setItem(key, value),
  removeItem: async (key: string) => window.localStorage.removeItem(key),
};

export const gate = config.liveGate
  ? new GateStore({
      userPoolId: config.cognitoPoolId,
      userPoolWebClientId: config.cognitoClientId,
      oauthDomain: `${config.appKey}-login-${config.stage}.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: `https://${config.stage === 'dev' ? 'dev.' : ''}${config.domain}/`,
      redirectSignOut: `https://${config.stage === 'dev' ? 'dev.' : ''}${config.domain}/`,
      awsRegion: 'us-east-1',
    })
  : new LocalGateStore('service.gate', storage);

/*
**********************************************************
TEMPORARILY DISABLED: NOT CHECKING THE DATABASE ANY LONGER
**********************************************************
// subscribe a function to capture when the user signs-in
gate.subscribe(async () => {
  if (gate.flags.processing) {
    return;
  }
  if (gate.user.hasAccess && gate.user.username) {
    store.loadCustomer();
  }
}, 'store.loadCustomer');
*/
