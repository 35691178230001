/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { IconChevronBack } from '@cpmech/iricons/IconChevronBack';
import { IconSchoolOutline } from '@cpmech/iricons/IconSchoolOutline';
import { IconStarOutline } from '@cpmech/iricons/IconStarOutline';
import { IconRocket } from '@cpmech/iricons/IconRocket';
import { IconSparkles } from '@cpmech/iricons/IconSparkles';
import { IconArrowDownCircleOutline } from '@cpmech/iricons/IconArrowDownCircleOutline';
import { IconDesktopOutline } from '@cpmech/iricons/IconDesktopOutline';
import { IconImageOutline } from '@cpmech/iricons/IconImageOutline';
import { IconReaderOutline } from '@cpmech/iricons/IconReaderOutline';
import {
  RcButton,
  rcConfig,
  rcDefaultMenuOptions,
  RcMenuEntry,
  RcMenuSubEntry,
  RcMenuVert,
  RcMenuVertProps,
  RcSpinDots,
} from '../rcomps';
import { getCourseLabel, getMediaItemLabel } from '../util';
import { control, store } from '../service';
import { styles } from '../styles';
import { useStore } from '../hooks';
import { IManifestItem } from '../data';
import { MenuLink, SearchBar, SearchResults } from '../components';

const expandSubMenu = (course: string): boolean => false; // course === 'civl4230';

const divider = (title: string) => (
  <div
    css={css`
      font-weight: bold;
      border-bottom: 1px solid #484848;
    `}
  >
    {title.toUpperCase()}
  </div>
);

const mlink = (onMenu: boolean, label: string, root: string, course: string, fontSize?: string) => (
  <MenuLink onMenu={onMenu} label={label} route={`/${root}/${course}`} fontSize={fontSize} />
);

const alink = (onMenu: boolean, item: IManifestItem, fontSize?: string) => (
  <MenuLink
    onMenu={onMenu}
    label={getMediaItemLabel(item)}
    route={`/article/${item.mdKey}`}
    fontSize={fontSize}
  />
);

const plink = (onMenu: boolean, item: IManifestItem, fontSize?: string) => (
  <MenuLink
    onMenu={onMenu}
    label={getMediaItemLabel(item)}
    route={`/presentation/${item.mdKey}/1`}
    fontSize={fontSize}
  />
);

const clink = (onMenu: boolean, mdKey: string, fontSize?: string) => (
  <MenuLink
    onMenu={onMenu}
    label={getCourseLabel(mdKey)}
    route={`/presentation/${mdKey}/1`}
    fontSize={fontSize}
  />
);

export interface SideBarProps {
  onMenu?: boolean;
}

export const SideBar: React.FC<SideBarProps> = ({ onMenu = false }) => {
  const { loadManifestError, loadManifestCompleted } = useStore('SideBar');
  const isNarrow = useMediaQuery({ maxWidth: rcConfig.media.phone.maxWidth });

  const iconSize = styles.dims.icon.small;
  const subIconSize = styles.dims.icon.tiny;

  const props: Omit<RcMenuVertProps, 'entries'> = {
    options: rcDefaultMenuOptions({
      iconSize,
      subIconSize,
      colorIcon: onMenu ? styles.colors.orange() : styles.colors.grey(),
      colorLabel: onMenu ? styles.colors.white() : styles.colors.blue(),
      colorLabelHover: onMenu ? styles.colors.grey(50) : styles.colors.blue(50),
      minWidth: isNarrow ? '80%' : styles.dims.sideBar.maxWidth,
      maxWidth: isNarrow ? '80%' : styles.dims.sideBar.maxWidth,
      gapVertEntries: '25px',
      paddingTop: isNarrow ? '40px' : '20px',
    }),
  };

  if (loadManifestError) {
    return <RcMenuVert entries={[{ comp: <div>Error</div>, separator: true }]} {...props} />;
  }

  if (!loadManifestCompleted) {
    return (
      <RcMenuVert
        entries={[{ comp: <RcSpinDots size={styles.dims.icon.large} />, separator: true }]}
        {...props}
      />
    );
  }

  const uqCourses = store.getCourses();
  const otherArticles = store.getNotCourseArticleManifests();
  const otherPresentations = store.getNotCoursePresentationManifests();

  const entries: RcMenuEntry[] = [
    {
      comp: divider('SEARCH RESULTS'),
      separator: true,
    },
    {
      comp: <SearchResults onMenu={onMenu} />,
    },
    {
      comp: divider('UQ COURSES'),
      separator: true,
    },
    ...uqCourses.map((course) => {
      const lectures = store.getCourseLectureManifests(course);
      const tutorials = store.getCourseTutorialManifests(course);
      const articles = store.getCourseArticleManifests(course);
      const entries: RcMenuSubEntry[] = [];
      if (lectures.length > 0) {
        entries.push({
          icon: <IconImageOutline size={iconSize} />,
          link: mlink(onMenu, 'LECTURES', 'lectures', course, '90%'),
          initShowSubSub: onMenu ? true : expandSubMenu(course),
          subSubEntries: lectures.map((presentation) => ({
            icon: <IconRocket size={subIconSize} />,
            link: clink(onMenu, presentation.mdKey, '80%'),
          })),
        });
      }
      if (tutorials.length > 0) {
        entries.push({
          icon: <IconImageOutline size={iconSize} />,
          link: mlink(onMenu, 'TUTORIALS', 'tutorials', course, '90%'),
          initShowSubSub: onMenu ? true : expandSubMenu(course),
          subSubEntries: tutorials.map((presentation) => ({
            icon: <IconDesktopOutline size={subIconSize} />,
            link: clink(onMenu, presentation.mdKey, '80%'),
          })),
        });
      }
      if (articles.length > 0) {
        entries.push({
          icon: <IconReaderOutline size={iconSize} />,
          link: mlink(onMenu, 'ARTICLES', 'articles', course, '90%'),
          initShowSubSub: onMenu ? true : false,
          subSubEntries: articles.map((article) => ({
            icon: <IconSparkles size={subIconSize} />,
            link: alink(onMenu, article, '80%'),
          })),
        });
      }
      if (course === 'civl4230' || course === 'civl6215') {
        entries.push({
          icon: <IconArrowDownCircleOutline size={iconSize} />,
          link: mlink(onMenu, 'RESOURCES', 'resources', course, '90%'),
        });
      }
      return {
        icon: <IconSchoolOutline size={iconSize} />,
        link: mlink(onMenu, course.toUpperCase(), 'article', course),
        initShowSub: expandSubMenu(course),
        entries,
      } as RcMenuEntry;
    }),
    {
      comp: divider('ARTICLES'),
      separator: true,
    },
    ...otherArticles.map(
      (article) =>
      ({
        icon: <IconStarOutline size={iconSize} />,
        link: alink(onMenu, article),
      } as RcMenuEntry),
    ),
  ];

  if (otherPresentations.length > 0) {
    entries.push(
      ...[
        {
          comp: divider('PRESENTATIONS'),
          separator: true,
        },
        ...otherPresentations.map(
          (presentation) =>
          ({
            icon: <IconStarOutline size={iconSize} />,
            link: plink(onMenu, presentation),
          } as RcMenuEntry),
        ),
      ],
    );
  }

  if (isNarrow) {
    return <RcMenuVert entries={[{ comp: <SearchBar /> }, ...entries]} {...props} />;
  }

  return (
    <div>
      <div
        css={css`
          text-align: right;
          margin-top: 20px;
        `}
      >
        <RcButton
          onClick={() => control.setShowSideBar(false)}
          icon={<IconChevronBack size={styles.dims.icon.medium} />}
          color={styles.colors.blue()}
          borderRadius="300px"
          paddingLeft="0px"
          paddingRight="0px"
          width="50px"
          height="50px"
          backgroundColor={styles.colors.white()}
        />
      </div>
      <RcMenuVert entries={entries} {...props} />
    </div>
  );
};
