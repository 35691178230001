import { SimpleStore } from '@cpmech/simple-state';

type Action = 'toggle';
const actionNames: Action[] = ['toggle'];

// define the state interface
interface IControlState {
  warningMessage: string;
  fullView: boolean;
  showWarning: boolean;
  showHeader: boolean;
  showLeftMenu: boolean;
  showSideBar: boolean;
  showFooter: boolean;
  allSlidesView: boolean;
  documentView: boolean;
  printModeView: boolean;
  previousFullView: boolean;
  previousDocumentView: boolean;
  previousShowHeader: boolean;
  previousShowSideBar: boolean;
  previousShowFooter: boolean;
}

// define a function to generate a blank state
const newZeroState = (): IControlState => ({
  warningMessage: '',
  fullView: false,
  showWarning: false,
  showHeader: true,
  showLeftMenu: false,
  showSideBar: true,
  showFooter: true,
  allSlidesView: false,
  documentView: false,
  printModeView: false,
  previousFullView: false,
  previousDocumentView: false,
  previousShowHeader: true,
  previousShowSideBar: true,
  previousShowFooter: true,
});

// extend the SimpleStore class; it may have any additional members
class Control extends SimpleStore<Action, IControlState, null> {
  constructor() {
    super(actionNames, newZeroState);
  }

  toggleFullView = () => {
    this.updateState('toggle', async () => {
      if (this.state.fullView) {
        this.state.fullView = false;
        this.state.showHeader = true;
        this.state.showSideBar = true;
        this.state.showFooter = true;
      } else {
        this.state.fullView = true;
        this.state.showHeader = false;
        this.state.showSideBar = false;
        this.state.showFooter = false;
      }
    });
  };

  toggleAllSlidesView = () => {
    this.updateState('toggle', async () => {
      if (this.state.allSlidesView) {
        this.state.allSlidesView = false;
      } else {
        this.state.allSlidesView = true;
        this.state.documentView = false;
      }
    });
  };

  toggleDocumentView = () => {
    this.updateState('toggle', async () => {
      if (this.state.documentView) {
        this.state.documentView = false;
      } else {
        this.state.allSlidesView = false;
        this.state.documentView = true;
      }
    });
  };

  togglePrintModeView = () => {
    this.updateState('toggle', async () => {
      if (this.state.printModeView) {
        this.state.printModeView = false;
        this.state.fullView = this.state.previousFullView;
        this.state.documentView = this.state.previousDocumentView;
        this.state.showHeader = this.state.previousShowHeader;
        this.state.showSideBar = this.state.previousShowSideBar;
        this.state.showFooter = this.state.previousShowFooter;
      } else {
        this.state.printModeView = true;
        this.state.previousFullView = this.state.fullView;
        this.state.previousDocumentView = this.state.documentView;
        this.state.previousShowHeader = this.state.showHeader;
        this.state.previousShowSideBar = this.state.showSideBar;
        this.state.previousShowFooter = this.state.showFooter;
        this.state.fullView = true;
        this.state.documentView = true;
        this.state.showHeader = false;
        this.state.showSideBar = false;
        this.state.showFooter = false;
      }
    });
  };

  setShowPresentation = () => {
    this.updateState('toggle', async () => {
      this.state.allSlidesView = false;
      this.state.documentView = false;
    });
  };

  setShowWarning = (value: boolean, message = 'WARNING') => {
    this.updateState('toggle', async () => {
      this.state.warningMessage = message;
      this.state.showWarning = value;
    });
  };

  setShowHeader = (value: boolean) => {
    this.updateState('toggle', async () => {
      this.state.showHeader = value;
    });
  };

  setShowLeftMenu = (value: boolean) => {
    this.updateState('toggle', async () => {
      this.state.showLeftMenu = value;
    });
  };

  setShowSideBar = (value: boolean) => {
    this.updateState('toggle', async () => {
      this.state.showSideBar = value;
    });
  };

  setShowFooter = (value: boolean) => {
    this.updateState('toggle', async () => {
      this.state.showFooter = value;
    });
  };
}

export const control = new Control();
