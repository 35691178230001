/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useStore } from '../hooks';
import { store } from '../service';
import { styles } from '../styles';
import { LoadingPage } from './LoadingPage';

export interface ArticlePageProps {
  mdKey: string;
}

export const ArticlePage: React.FC<ArticlePageProps> = ({ mdKey }) => {
  const { updateMediaItemInProgress } = useStore('ArticlePage');

  useEffect(() => {
    store.loadMediaItem('ARTICLE', mdKey);
  }, [mdKey]);

  if (updateMediaItemInProgress) {
    return <LoadingPage />;
  }

  const article = store.getMediaItem('ARTICLE', mdKey);

  if (!article || article.htmls.length < 1) {
    return null;
  }

  return (
    <div css={styles.article.root}>
      <div dangerouslySetInnerHTML={{ __html: article.htmls[0].html }} />
    </div>
  );
};
