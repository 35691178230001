/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Fragment, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { IconClose } from '@cpmech/iricons/IconClose';
import { RcError, RcInput, RcInputElementCssOptions, RcLinkOrDiv } from '../../rcomps';
import {
  ISignaturePadOptions,
  ISignaturePadOptionsForm,
  ISignaturePadOptionsError,
  newZeroSignaturePadOptionsErrors,
  data2signaturePadOptionsForm,
  checkSignaturePadOptionsForm,
  newDefaultSignaturePadOptions,
  signaturePadOptionsForm2data,
} from '../../service';
import { styles } from '../../styles';

const rcInputProps: RcInputElementCssOptions = {
  height: '32px',
  fontSize: '14px',
  labelFontSize: '14px',
  darkMode: true,
  color: styles.colors.grey(),
  bgColor: styles.colors.grey(),
  paddingHoriz: '5px',
  labelPaddingHoriz: '0px',
  marginVert: '10px',
  borderWidth: '0px',
};

const cssButton = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`;

export interface SetPadOptionsProps {
  onSubmit: (options: ISignaturePadOptions) => void;
}

export const SetPadOptions: React.FC<SetPadOptionsProps> = ({ onSubmit }) => {
  const [values, setValues] = useState<ISignaturePadOptionsForm>(
    data2signaturePadOptionsForm(newDefaultSignaturePadOptions()),
  );
  const [vErrors, setVerrors] = useState<ISignaturePadOptionsError>(
    newZeroSignaturePadOptionsErrors(),
  );
  const [touchedButtons, setTouchedButtons] = useState(false);

  const validate = (): boolean => {
    const { errors, hasError } = checkSignaturePadOptionsForm(values);
    setVerrors(errors);
    return !hasError;
  };

  const submit = () => {
    setTouchedButtons(true);
    if (validate()) {
      onSubmit(signaturePadOptionsForm2data(values));
    }
  };

  const resetDefaults = () => {
    setValues(data2signaturePadOptionsForm(newDefaultSignaturePadOptions()));
  };

  const setValue = <K extends keyof ISignaturePadOptionsForm>(key: K, value: string) => {
    const newValues = { ...values, [key]: value.trimLeft() };
    setValues(newValues);
    if (touchedButtons) {
      const { errors } = checkSignaturePadOptionsForm(newValues);
      setVerrors(errors);
    }
  };

  const buttonClose = (
    <div
      css={css`
        color: white;
        position: absolute;
        top: 3px;
        right: 3px;
        z-index: 2;
      `}
    >
      <RcLinkOrDiv
        onClick={submit}
        color={styles.colors.grey(75)}
        hoverColor={styles.colors.grey(25)}
      >
        <IconClose size={styles.dims.icon.medium} />
      </RcLinkOrDiv>
    </div>
  );

  const buttonSave = (
    <div css={cssButton}>
      <RcLinkOrDiv
        onClick={submit}
        color={styles.colors.grey(75)}
        hoverColor={styles.colors.grey(25)}
      >
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>SAVE</span>
      </RcLinkOrDiv>
    </div>
  );

  const buttonResetDefaults = (
    <div css={cssButton}>
      <RcLinkOrDiv
        onClick={resetDefaults}
        color={styles.colors.grey(75)}
        hoverColor={styles.colors.grey(25)}
      >
        <span style={{ textDecoration: 'underline' }}>reset defaults</span>
      </RcLinkOrDiv>
    </div>
  );

  const fields = (
    <Fragment>
      <RcInput
        label="PEN COLOR"
        value={values.penColor}
        onChange={(e) => setValue('penColor', e.target.value)}
        error={vErrors.penColor}
        {...rcInputProps}
      />
      <RcError error={vErrors.penColor} />
      <HexColorPicker color={values.penColor} onChange={(c) => setValue('penColor', c)} />
    </Fragment>
  );

  return (
    <div
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${styles.colors.grey()};
        border-radius: 3px;
        z-index: 1;
        max-height: calc(100% - 20px);
        overflow-y: auto;
      `}
    >
      {buttonClose}
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding: 20px 20px;
          `}
        >
          {buttonResetDefaults}
          {buttonSave}
          {fields}
          {buttonSave}
          {buttonResetDefaults}
        </div>
      </div>
    </div>
  );
};
