export const findPageTitle = (): string => {
  const h1 = document.getElementsByTagName('h1');
  if (h1.length > 0) {
    return h1[0].innerHTML;
  }

  const h2 = document.getElementsByTagName('h2');
  if (h2.length > 0) {
    return h2[0].innerHTML;
  }

  const h3 = document.getElementsByTagName('h3');
  if (h3.length > 0) {
    return h3[0].innerHTML;
  }

  return 'document';
};
