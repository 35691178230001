/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { objKeys } from '@cpmech/util';
import { RcButton, RcError, RcInput, RcModalYesNo } from '../rcomps';
import { gate, store } from '../service';
import { styles } from '../styles';

export type IStudentNumForm = {
  studentNumber: string;
};

export type IStudentNumError = IStudentNumForm;

export const checkStudentNumForm = (
  values: IStudentNumForm,
): { errors: IStudentNumError; hasError: boolean } => {
  const errors: IStudentNumError = {
    studentNumber:
      //isValidPositiveNumber(values.studentNumber) && values.studentNumber.length === 8
      values.studentNumber.length > 4 ? '' : 'Invalid value',
  };
  const hasError = objKeys(errors).findIndex((key) => errors[key] !== '') >= 0;
  return { errors, hasError };
};

export const StudentNumberPage: React.FC = () => {
  const [values, setValues] = useState<IStudentNumForm>({
    studentNumber: store.getStudentNumber(),
  });
  const [vErrors, setVerrors] = useState<IStudentNumError>({ studentNumber: '' });
  const [touchedButtons, setTouchedButtons] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const done = async () => {
    await store.saveStudentNumber(values.studentNumber);
    await gate.signOut();
  };

  const validate = (): boolean => {
    const { errors, hasError } = checkStudentNumForm(values);
    setVerrors(errors);
    return !hasError;
  };

  const submit = () => {
    setTouchedButtons(true);
    if (validate()) {
      setShowConfirm(true);
    }
  };

  const setValue = <K extends keyof IStudentNumForm>(key: K, value: string) => {
    const newValues = { ...values, [key]: value.trimLeft() };
    setValues(newValues);
    if (touchedButtons) {
      const { errors } = checkStudentNumForm(newValues);
      setVerrors(errors);
    }
  };

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
        `}
      >
        <div>
          <h2>Student number</h2>
        </div>
        <div>What is your student number, please?</div>
        <div>
          <RcInput
            name="studentNumber"
            label="Student Number"
            borderColor={styles.colors.blue()}
            hlColor={styles.colors.blue()}
            height={styles.dims.input.height}
            borderRadius={styles.dims.input.borderRadius}
            onChange={(e) => setValue('studentNumber', e.target.value)}
            onEnterKeyUp={submit}
            error={vErrors.studentNumber}
          />
          <RcError error={vErrors.studentNumber} />
        </div>
        <div>
          <RcButton onClick={submit} width="150px">
            SAVE
          </RcButton>
        </div>
      </div>

      {showConfirm && (
        <RcModalYesNo
          onClose={() => setShowConfirm(false)}
          onNo={() => setShowConfirm(false)}
          onYes={done}
          leftButtonStyle={{ color: styles.colors.green(), outline: false }}
          rightButtonStyle={{ color: styles.colors.red(), outline: false }}
          maxWidth="450px"
        >
          <p>
            Please confirm updating your student number and note that you will have to{' '}
            <b>sign in again</b>.
          </p>
        </RcModalYesNo>
      )}
    </div>
  );
};
