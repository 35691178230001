/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconStar } from '@cpmech/iricons/IconStar';
import { RcLinkOrDiv, RcPair } from '../rcomps';
import { MediaKind } from '../data';
import { store } from '../service';
import { styles } from '../styles';
import { useNav } from '../hooks';

const courseKey = (mdKey: string) => {
  if (mdKey.startsWith('civl')) {
    const label = mdKey.slice(9);
    if (label.startsWith('tut') || label.startsWith('lec')) {
      return `${label.slice(0, 5).replace(/^./, (m) => m.toUpperCase())} - `;
    }
  }
  return '';
};

export interface ListMediaItemsPageProps {
  kind: MediaKind;
  course?: string;
  tutorial?: boolean;
}

export const ListMediaItemsPage: React.FC<ListMediaItemsPageProps> = ({
  kind,
  course,
  tutorial,
}) => {
  const { goto } = useNav();

  const list = course
    ? kind === 'ARTICLE'
      ? store.getCourseArticleManifests(course)
      : tutorial
      ? store.getCourseTutorialManifests(course)
      : store.getCourseLectureManifests(course)
    : kind === 'ARTICLE'
    ? store.getNotCourseArticleManifests()
    : store.getNotCoursePresentationManifests();

  const prefix = course ? `${course.toUpperCase()} ` : '';

  const header = course
    ? kind === 'ARTICLE'
      ? 'ARTICLES'
      : tutorial
      ? 'TUTORIALS'
      : 'LECTURES'
    : `${kind.toUpperCase()}S`;

  return (
    <div
      css={css`
        background-color: #ffffff;
        margin: 60px 60px;
        font-size: 1.3em;
      `}
    >
      <h2>{prefix + header}</h2>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: baseline;
        `}
      >
        {list.map((item, i) => (
          <div
            key={i}
            css={css`
              margin: 10px;
            `}
          >
            <RcLinkOrDiv
              onClick={() => goto({ route: kind.toLowerCase(), sub: item.mdKey, id: '1' })}
              color={styles.colors.blue()}
              hoverColor={styles.colors.blue(50)}
            >
              <RcPair
                left={<IconStar size={styles.dims.icon.medium} />}
                right={courseKey(item.mdKey) + store.getTitle(kind, item.mdKey)}
                cssRight="line-height: 1.5em"
                spacing="20px"
              />
            </RcLinkOrDiv>
          </div>
        ))}
      </div>
    </div>
  );
};
