import { Iany } from '@cpmech/basic';

export interface IImg {
  src: string;
  alt: string;
}

export type IHtml = { html: string; imgs: IImg[] };

export type AccessGroup = 'all' | string;

export type MediaKind = 'ARTICLE' | 'PRESENTATION';

export interface IMediaItem {
  kind: MediaKind;
  mdKey: string;
  title: string;
  htmls: IHtml[]; // one for each slide, including math, highlighted code, code output, and a base64 png file generated by the Python
  metadata?: Iany;
}

export type IManifestItem = {
  accessGroup: AccessGroup; // e.g. "ALL", "civl6215"
  relDirname: string; // e.g. /presentation/civl6215/tw01
  mdKey: string; // e.g. civl6215-tw01-introduction
  title: string; // e.g. Introduction to Ground Improvement and Remediation Technologies
  link?: string; // link from this item to another item
};

export type IAllMedia = { [kind in MediaKind]: { [mdKey: string]: IMediaItem } };

export type IManifest = { [kind in MediaKind]: { [mdKey: string]: IManifestItem } };

export type ISearchIndexInput = { [route: string]: string };

export interface IMediaConfig {
  accessGroup?: { [mdKey: string]: string };
  link?: { [mdKey: string]: string };
}

export const newZeroMediaItem = (kind: MediaKind): IMediaItem => ({
  kind,
  mdKey: '',
  title: '',
  htmls: [],
});

export const newZeroManifestItem = (): IManifestItem => ({
  accessGroup: '',
  relDirname: '',
  mdKey: '',
  title: '',
});

export const newZeroManifest = (): IManifest => ({
  ARTICLE: {},
  PRESENTATION: {},
});

export const newZeroAllMedia = (): IAllMedia => ({
  ARTICLE: {},
  PRESENTATION: {},
});

export const newZeroMediaConfig = (): IMediaConfig => ({});

export const refMediaItem: IMediaItem = {
  kind: 'ARTICLE',
  mdKey: '',
  title: '',
  htmls: [{ html: '', imgs: [{ src: '', alt: '' }] }],
};

export const refManifestItem: IManifestItem = {
  accessGroup: '',
  relDirname: '',
  mdKey: '',
  title: '',
};

export const refManifest: IManifest = {
  ARTICLE: {},
  PRESENTATION: {},
};

export const refAllMedia: IAllMedia = {
  ARTICLE: {},
  PRESENTATION: {},
};

export const refMediaConfig: IMediaConfig = {
  accessGroup: {},
  link: {},
};

export const optMediaItem = {
  metadata: true,
};

export const optMediaConfig: { [P in keyof Required<IMediaConfig>]: boolean } = {
  accessGroup: true,
  link: true,
};

export const optManifestItem = {
  link: true,
};
