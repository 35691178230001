export interface ITheme {
  title: { fg: string; bg: string };
  section: { fg: string; bg: string };
  slides: { fg: string; bg: string };
}

export const themes: { [key: string]: ITheme } = {
  colorful1: {
    title: {
      fg: '#ffffff',
      bg: `background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);`,
    },
    slides: {
      fg: '#484848',
      bg: `background: linear-gradient(to top, #dfe9f3 0%, white 100%);`,
    },
  },

  colorful2: {
    title: {
      fg: '#ffffff',
      bg: `background: linear-gradient(180deg, #2af598 0%, #009efd 100%);`,
    },
    section: {
      fg: '#ffffff',
      bg: `background: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);`,
    },
    slides: {
      fg: '#484848',
      bg: `background: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);`,
    },
  },

  colorful3: {
    title: {
      fg: '#ffffff',
      bg: `background: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);`,
    },
    slides: {
      fg: '#484848',
      bg: `background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);`,
    },
  },

  colorful4: {
    title: {
      fg: '#343434',
      bg: `background: linear-gradient(109.6deg, rgba(255,115,68,1) 11.2%, rgba(244,255,32,1) 100.6% );`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(0.2deg, rgba(252,220,115,1) -13.1%, rgba(252,194,115,1) 31.9%, rgba(241,84,84,1) 69.6%);`,
    },
    slides: {
      fg: '#343434',
      bg: `background: linear-gradient(109.5deg, rgba(229,233,177,1) 11.2%, rgba(223,205,187,1) 100.2% );`,
    },
  },

  red1: {
    title: {
      fg: '#ffffff',
      bg: `background: linear-gradient(178.2deg, rgba(118,8,23,1) 10.9%, rgba(158,12,33,1) 62.6%);`,
    },
    section: {
      fg: '#ffffff',
      bg: `background: linear-gradient(25deg, #d64c7f, #ee4758 50%);`,
    },
    slides: {
      fg: '#484848',
      bg: `background: linear-gradient(32deg, rgba(251,220,220,1) 0%, rgba(255,255,255,1) 100%);`,
    },
  },

  blue1: {
    title: {
      fg: '#ffffff',
      bg: `background: radial-gradient(circle farthest-corner at 16.5% 28.1%, rgba(15,27,49,1) 0%, rgba(0,112,218,1) 90%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(358.7deg, rgba(42,136,157,1) 1.7%, rgba(122,197,214,1) 51.1%, rgba(211,232,242,1) 95.5%);`,
    },
    slides: {
      fg: '#484848',
      bg: `background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);`,
    },
  },

  green1: {
    title: {
      fg: '#343434',
      bg: `background: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(132deg, #ffffff 0%, #16A085 100%);`,
    },
    slides: {
      fg: '#343434',
      bg: `background: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%);`,
    },
  },

  yellow1: {
    title: {
      fg: '#343434',
      bg: `background: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(315deg, #ffe9d4 0%, #ffd28a 50%, #ffedb3 100%);`,
    },
    slides: {
      fg: '#343434',
      bg: `background: linear-gradient(62deg, #ffe9d4 0%, #ffffff 100%);`,
    },
  },

  silver1: {
    title: {
      fg: '#343434',
      bg: `background: linear-gradient(90deg, #ffffff 0%, #898989 100%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(225deg, #ffffff 0%, #c3c3c3 100%);`,
    },
    slides: {
      fg: '#484848',
      bg: `background: linear-gradient(19deg, #ffffff 0%, #e9e6f3 100%);`,
    },
  },

  purple1: {
    title: {
      fg: '#ffffff',
      bg: `background: linear-gradient(225deg, #000000 0%, #784BA0 50%, #0c456f 100%);`,
    },
    section: {
      fg: '#343434',
      bg: `background: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);`,
    },
    slides: {
      fg: '#343434',
      bg: `background-image: linear-gradient(45deg, #ddd0e9 0%, #ffdde1 100%);`,
    },
  },
};

// Reference:
//   https://cssgradient.io/gradient-backgrounds/
