import { Iany } from '@cpmech/basic';
import { ITheme, themes } from '../styles/themes';

const rxH2 = new RegExp(/<h2>(.*?)<\/h2>/s);

export const metadata2colors = (
  idxSlide: number,
  numSlides: number,
  html: string,
  metadata?: Iany,
  defaultTheme: ITheme = themes.colorful1,
): { color: string; background: string } => {
  const theme: ITheme =
    metadata && metadata.theme && themes[metadata.theme] ? themes[metadata.theme] : defaultTheme;

  const h2Arr = html.match(rxH2);
  if (h2Arr) {
    const rest = html
      .replace(rxH2, '')
      .replace(/\./g, '')
      .replace(/(?:\r\n|\r|\n)/g, '');
    if (rest === '') {
      return { color: theme.section.fg, background: theme.section.bg };
    }
  }

  if (idxSlide === 0 || idxSlide === numSlides - 1) {
    return { color: theme.title.fg, background: theme.title.bg };
  }

  return { color: theme.slides.fg, background: theme.slides.bg };
};
