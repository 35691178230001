export interface IResource {
  title: string;
  author: string;
  year: string;
  filename: string;
}

export type IResources = { [course: string]: { [week: string]: IResource[] } };

export const resources: IResources = {
  civl4230: {
    'Week 2': [
      {
        title: 'Solution of Liquid–Gas–Solid Coupled Equations for Porous Media Considering Dynamics and Hysteretic Retention Behavior, Journal of Engineering Mechanics  ASCE, 143(6), doi.org/10.1061/(ASCE)EM.1943-7889.0001208',
        author: 'Pedroso, Zhang, Ehlers',
        year: '2017',
        filename: 'Pedroso-Zhang-Ehlers-2017-Liquid-Gas-Solid-Porous-Media-JEM.pdf',
      },
    ]
  },
  civl6215: {
    'All weeks': [
      {
        title: 'Ground modification methods I',
        author: 'US FHWA',
        year: '2017',
        filename: 'FHWA-NHI-16-027-ground-modification-1.pdf',
      },
      {
        title: 'Ground modification methods II',
        author: 'US FHWA',
        year: '2017',
        filename: 'FHWA-NHI-16-028-ground-modification-2.pdf',
      },
    ],
    'Week 4': [
      {
        title: 'Lift thickness and embankments',
        author: 'Fratta & Horsfall',
        year: '2015',
        filename: 'wisdot-lift-thickness-limits.pdf',
      },
      {
        title: 'Intelligent compaction technology',
        author: 'US FHWA',
        year: '2011',
        filename: 'FHWA-IF-12-002-intel-compaction.pdf',
      },
    ],
    'Week 5': [
      {
        title: 'Development of ground column technologies',
        author: 'Han',
        year: '2015',
        filename: 'han-ground-column-technologies.pdf',
      },
      {
        title: 'Vibro-stone column technique',
        author: 'McCabe et al',
        year: '2007',
        filename: 'mccabe-vibrostone-columns.pdf',
      },
      {
        title: 'The design of vibro replacement',
        author: 'Priebe',
        year: '1995',
        filename: 'priebe-1995-vibro-replacement.pdf',
      },
      {
        title: 'Vibro replacement to prevent liquefaction',
        author: 'Priebe',
        year: '1998',
        filename: 'priebe-1998-vibro-replacement-liquefaction.pdf',
      },
    ],
    'Week 6': [
      {
        title: 'Micropile design and construction',
        author: 'US FHWA',
        year: '2005',
        filename: 'FHWA-NHI-05-039-micropiles.pdf',
      },
      {
        title: 'Underpinning and lifting with micropiles',
        author: 'HB',
        year: '2008',
        filename: 'hb-camp-porter-barracks-stabilization-micropiles.pdf',
      },
    ],
    'Week 7': [
      {
        title: 'The 2014 Baltimore Case',
        author: 'Video',
        year: '2014',
        filename: 'BaltimoreLandslide.mp4',
      },
      {
        title: 'Ground anchors',
        author: 'US FHWA',
        year: '1999',
        filename: 'FHWA-IF-99-015-anchors.pdf',
      },
    ],
    'Week 8': [
      {
        title: 'Soil nail walls',
        author: 'US FHWA',
        year: '2015',
        filename: 'FHWA-14-007-soil-nail-walls.pdf',
      },
      {
        title: 'Launched soil nails',
        author: 'US FHWA',
        year: '1994',
        filename: 'FHWA-FPL-93-003-launched-nails.pdf',
      },
      {
        title: 'Evolution of launched soil nails',
        author: 'Barrett',
        year: '2016',
        filename: 'barret-evolution-of-launched-nails.pdf',
      },
      {
        title: 'Short introduction to launched soil nails',
        author: 'Rocscience',
        year: '2018',
        filename: 'rocscience-launched-soil-nails.pdf',
      },
    ],
  },
};
