/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { IconSettingsOutline } from '@cpmech/iricons/IconSettingsOutline';
import { IconTrashOutline } from '@cpmech/iricons/IconTrashOutline';
import { RcLinkOrDiv } from '../../rcomps';
import { SetPadOptions } from './SetPadOptions';
import { fitCanvasToParent } from '../../util';
import { ISignaturePadOptions } from '../../service';
import { styles } from '../../styles';
import { useCallbackRef, useControl } from '../../hooks';

export const DrawingPad: React.FC = () => {
  const { fullView } = useControl('DrawingPad');
  const refPad = useRef<SignaturePad | null>(null);
  const [showPadOptions, setShowPadOptions] = useState(false);

  const setRefCanvas = useCallbackRef<HTMLCanvasElement>(
    (canvas) => {
      fitCanvasToParent(canvas);
      refPad.current = new SignaturePad(canvas, {
        penColor: styles.colors.red(),
        dotSize: 3,
        minWidth: 2,
        maxWidth: 4,
      });
    },
    (_) => {
      if (refPad.current) {
        refPad.current.off();
      }
    },
    [],
  );

  useEffect(() => {
    if (refPad.current) {
      refPad.current.clear();
    }
  });

  const clear = () => {
    if (refPad.current) {
      refPad.current.clear();
    }
  };

  const handleOnSubmit = (options: ISignaturePadOptions) => {
    if (refPad.current) {
      if (options.penColor) {
        refPad.current.clear();
        refPad.current.penColor = options.penColor;
      }
    }
    setShowPadOptions(false);
  };

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        ${styles.toolbar.variables(fullView)}
      `}
    >
      <div css={styles.cascade.viewport}>
        {/* canvas */}
        <div css={styles.cascade.absoluteZero}>
          <canvas ref={setRefCanvas} />
        </div>

        {/* tool-eraser */}
        <div css={styles.toolbar.cssTopRight1()}>
          <RcLinkOrDiv
            onClick={clear}
            color={styles.toolbar.color}
            hoverColor={styles.toolbar.hoverColor}
          >
            <IconTrashOutline size="100%" />
          </RcLinkOrDiv>
        </div>

        {/* tool-options */}
        <div css={styles.toolbar.cssTopRight2()}>
          <RcLinkOrDiv
            onClick={() => setShowPadOptions(!showPadOptions)}
            color={styles.toolbar.color}
            hoverColor={styles.toolbar.hoverColor}
          >
            <IconSettingsOutline size="100%" />
          </RcLinkOrDiv>
        </div>

        {/* options */}
        {showPadOptions && <SetPadOptions onSubmit={handleOnSubmit} />}
      </div>
    </div>
  );
};
