/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { IconPrint } from '@cpmech/iricons/IconPrint';
import { IconImage } from '@cpmech/iricons/IconImage';
import { isValidPositiveNumber } from '@cpmech/util';
import { RcPairLinkOrDiv } from '../rcomps';
import { Slides, SlidesPrintMode } from '../components';
import { LoadingPage } from './LoadingPage';
import { styles } from '../styles';
import { useStore } from '../hooks';
import { control, store } from '../service';

export interface PresentationPageProps {
  mdKey: string;
  slideId: string;
}

export const PresentationPage: React.FC<PresentationPageProps> = ({ mdKey, slideId }) => {
  const { updateMediaItemInProgress } = useStore('PresentationPage');

  const presentationView = !control.state.allSlidesView && !control.state.documentView;

  useEffect(() => {
    store.loadMediaItem('PRESENTATION', mdKey);
  }, [mdKey]);

  if (updateMediaItemInProgress) {
    return <LoadingPage />;
  }

  const idxSlide = isValidPositiveNumber(slideId || '') ? Number(slideId) - 1 : 0;

  const toolbar = presentationView ? (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        gap: 30px;
        padding: 30px 60px;
      `}
    >
      <RcPairLinkOrDiv
        left={<IconPrint size={styles.dims.icon.medium} />}
        right={'Show document'}
        onClick={() => control.toggleDocumentView()}
      />
      <RcPairLinkOrDiv
        left={<IconPrint size={styles.dims.icon.medium} />}
        right={'Show all slides'}
        onClick={() => control.toggleAllSlidesView()}
      />
    </div>
  ) : (
    <div
      css={css`
        padding: 60px 30px;
        @media print {
          display: none;
        }
      `}
    >
      <RcPairLinkOrDiv
        left={<IconImage size={styles.dims.icon.medium} />}
        right={'Show presentation'}
        onClick={() => control.setShowPresentation()}
      />
    </div>
  );

  return (
    <div
      css={css`
        background-color: ${styles.colors.white()};
      `}
    >
      {presentationView && <Slides mdKey={mdKey} idxSlide={idxSlide} />}
      {!control.state.printModeView && toolbar}
      {!presentationView && (
        <div
          css={css`
            padding-bottom: 60px;
          `}
        >
          <SlidesPrintMode mdKey={mdKey} />
          {toolbar}
        </div>
      )}
    </div>
  );
};
