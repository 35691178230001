import { isValidPositiveNumber, objKeys } from '@cpmech/util';
import { styles } from '../styles';

export interface ISignaturePadOptions {
  minWidth?: number;
  maxWidth?: number;
  dotSize?: number;
  minDistance?: number;
  throttle?: number;
  velocityFilterWeight?: number;
  backgroundColor?: string;
  penColor?: string;
}

export const newDefaultSignaturePadOptions = (): ISignaturePadOptions => ({
  minWidth: 1.0,
  maxWidth: 3.0,
  dotSize: 2.0, // (minWidth + maxWidth) / 2
  minDistance: 5, // in pixels
  throttle: 16, // in miliseconds
  velocityFilterWeight: 0.7,
  backgroundColor: 'rgba(0,0,0,0)',
  penColor: styles.colors.red(),
});

export type ISignaturePadOptionsForm = { [P in keyof Required<ISignaturePadOptions>]: string };

export type ISignaturePadOptionsError = ISignaturePadOptionsForm;

export const newZeroSignaturePadOptionsForm = (): ISignaturePadOptionsForm => ({
  minWidth: '',
  maxWidth: '',
  dotSize: '',
  minDistance: '',
  throttle: '',
  velocityFilterWeight: '',
  backgroundColor: '',
  penColor: '',
});

export const newZeroSignaturePadOptionsErrors = newZeroSignaturePadOptionsForm;

export const checkSignaturePadOptionsForm = (
  values: ISignaturePadOptionsForm,
): { errors: ISignaturePadOptionsError; hasError: boolean } => {
  const errors: ISignaturePadOptionsError = {
    minWidth: isValidPositiveNumber(values.minWidth) ? '' : 'Invalid value',
    maxWidth: isValidPositiveNumber(values.maxWidth) ? '' : 'Invalid value',
    dotSize: isValidPositiveNumber(values.dotSize) ? '' : 'Invalid value',
    minDistance: isValidPositiveNumber(values.minDistance) ? '' : 'Invalid value',
    throttle: isValidPositiveNumber(values.throttle) ? '' : 'Invalid value',
    velocityFilterWeight: isValidPositiveNumber(values.velocityFilterWeight) ? '' : 'Invalid value',
    backgroundColor: '',
    penColor: '',
  };
  const hasError = objKeys(errors).findIndex((key) => errors[key] !== '') >= 0;
  return { errors, hasError };
};

export const data2signaturePadOptionsForm = (
  data: ISignaturePadOptions,
): ISignaturePadOptionsForm => ({
  minWidth: String(data.minWidth || ''),
  maxWidth: String(data.maxWidth || ''),
  dotSize: String(data.dotSize || ''),
  minDistance: String(data.minDistance || ''),
  throttle: String(data.throttle || ''),
  velocityFilterWeight: String(data.velocityFilterWeight || ''),
  backgroundColor: data.backgroundColor || '',
  penColor: data.penColor || '',
});

export const signaturePadOptionsForm2data = (
  form: ISignaturePadOptionsForm,
): ISignaturePadOptions => ({
  minWidth: form.minWidth ? Number(form.minWidth) : undefined,
  maxWidth: form.maxWidth ? Number(form.maxWidth) : undefined,
  dotSize: form.dotSize ? Number(form.dotSize) : undefined,
  minDistance: form.minWidth ? Number(form.minDistance) : undefined,
  throttle: form.throttle ? Number(form.throttle) : undefined,
  velocityFilterWeight: form.velocityFilterWeight ? Number(form.velocityFilterWeight) : undefined,
  backgroundColor: form.backgroundColor ? form.backgroundColor : undefined,
  penColor: form.penColor ? form.penColor : undefined,
});
