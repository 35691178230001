/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  gateParams,
  GateSignUpForm,
  GateStore,
  LocalGateSignUpForm,
  LocalGateStore,
} from '../gate';
import { Earth } from '../components/auxiliary';
import { config, gate } from '../service';
import { styles } from '../styles';

gateParams.loading.colorTitle = styles.colors.grey();
gateParams.loading.colorSpinner = styles.colors.grey();

const logo = (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
  >
    <div>
      <Earth />
    </div>
  </div>
);

export const SignInPage: React.FC = () => {
  const signInForm = config.liveGate ? (
    <GateSignUpForm
      gate={gate as GateStore}
      logo={logo}
      mayHideEmailLogin={false}
      initShownEmailLogin={false}
      withFederated={false}
    />
  ) : (
    <LocalGateSignUpForm gate={gate as LocalGateStore} logo={logo} ignoreErrors={true} />
  );

  return (
    <div
      css={css`
        background-color: #ffffff;
        margin: 60px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      `}
    >
      {signInForm}
    </div>
  );
};
