import { css } from '@emotion/react';
import { rcConfig } from '../rcomps';
import { colors } from './colors';
import { dims } from './dims';
import { ISize, ISizes } from './types';

export type SLayout = 'nofig' | 'row1' | 'row2col1' | 'row2col2' | 'row3col2' | 'row3col1';

class Slide {
  constructor(
    private size: ISizes = {
      h1: { min: 10, max: 220, cap: 40, vw: 4 },
      h2: { min: 8, max: 140, cap: 32, vw: 3 },
      h3: { min: 6, max: 100, cap: 18, vw: 2 },
      p: { min: 6, max: 100, cap: 18, vw: 1.8 },
      li: { min: 6, max: 100, cap: 18, vw: 2 },
      lis: { min: 4, max: 36, cap: 14, vw: 0.8 },
      pad: { min: 10, max: 160, cap: 80, vw: 6 },
      sep: { min: 2, max: 60, cap: 60, vw: 3 },
    },
    private borderRadius = '8px',
  ) {}

  private clamp = (d: ISize, sf = 1) => `clamp(${d.min}px, ${d.vw * sf}vw, ${d.max}px)`;

  private variables = (fullView: boolean) =>
    fullView
      ? ` --h1:  ${this.clamp(this.size.h1)};
          --h2:  ${this.clamp(this.size.h2)};
          --h3:  ${this.clamp(this.size.h3)};
          --p:   ${this.clamp(this.size.p)};
          --li:  ${this.clamp(this.size.li)};
          --lis: ${this.clamp(this.size.lis)};
          --pad: ${this.clamp(this.size.pad)};
          --sep: ${this.clamp(this.size.sep)};
        `
      : ` --h1:  ${this.clamp(this.size.h1)};
          --h2:  ${this.clamp(this.size.h2)};
          --h3:  ${this.clamp(this.size.h3)};
          --p:   ${this.clamp(this.size.p)};
          --li:  ${this.clamp(this.size.p)};
          --lis: ${this.clamp(this.size.lis)};
          --pad: ${this.clamp(this.size.pad)};
          --sep: ${this.clamp(this.size.sep)};

          @media screen and (min-width: ${rcConfig.media.phone.maxWidth}px) {
            --h1:  calc(${this.clamp(this.size.h1, 0.6)});
            --h2:  calc(${this.clamp(this.size.h2, 0.6)});
            --h3:  calc(${this.clamp(this.size.h3, 0.6)});
            --p:   calc(${this.clamp(this.size.p, 0.6)});
            --li:  calc(${this.clamp(this.size.li, 0.6)});
            --lis: calc(${this.clamp(this.size.lis, 0.6)});
            --pad: calc(${this.clamp(this.size.pad, 0.6)});
            --sep: calc(${this.clamp(this.size.sep, 0.5)});
          }

          @media screen and (min-width: ${rcConfig.media.tablet.maxWidth}px) {
            --h1:  calc(${this.clamp(this.size.h1, 0.7)});
            --h2:  calc(${this.clamp(this.size.h2, 0.7)});
            --h3:  calc(${this.clamp(this.size.h3, 0.7)});
            --p:   calc(${this.clamp(this.size.p, 0.7)});
            --li:  calc(${this.clamp(this.size.li, 0.7)});
            --lis: calc(${this.clamp(this.size.lis, 0.7)});
            --pad: calc(${this.clamp(this.size.pad, 0.7)});
            --sep: calc(${this.clamp(this.size.sep, 0.6)});
          }

          @media screen and (min-width: 1000px) {
            --h1:  calc(${this.clamp(this.size.h1, 0.8)});
            --h2:  calc(${this.clamp(this.size.h2, 0.8)});
            --h3:  calc(${this.clamp(this.size.h3, 0.8)});
            --p:   calc(${this.clamp(this.size.p, 0.8)});
            --li:  calc(${this.clamp(this.size.li, 0.8)});
            --lis: calc(${this.clamp(this.size.lis, 0.8)});
            --pad: calc(${this.clamp(this.size.pad, 0.8)});
            --sep: calc(${this.clamp(this.size.sep, 0.7)});
          }

          @media screen and (min-width: ${dims.minMaxPageWidth}px) {
            --h1:  ${this.size.h1.cap}px;
            --h2:  ${this.size.h2.cap}px;
            --h3:  ${this.size.h3.cap}px;
            --p:   ${this.size.p.cap}px;
            --li:  ${this.size.li.cap}px;
            --lis: ${this.size.lis.cap}px;
            --pad: ${this.size.pad.cap}px;
            --sep: ${this.size.sep.cap * 0.7}px;
          }
        `;

  private dpCodeCommon = (showOutput = false, showFigure = false) => `
    .dp-code-out {
      display: ${showOutput ? 'inline' : 'none'};
      code {
        border: 1px dashed #484848;
      }
    }
    .dp-code-fig {
      visibility: ${showFigure ? 'visible' : 'hidden'};
      position: absolute;
      top: 0;
      left: var(--pad);
      right: var(--pad);
      bottom: 0;
      ${this.dpCenCommon};
      img {
        height: 80%;
        width: 80%;
        object-fit: contain;
      }
    }
  `;

  cssMain = (fullView: boolean, showCodeOutput = false, showCodeFigure = false) =>
    fullView
      ? css`
          ${this.variables(fullView)}
          ${this.dpCodeCommon(showCodeOutput, showCodeFigure)}

          position: relative;
          border-radius: ${this.borderRadius};
          border: 1px solid ${colors.grey()};
          background-color: ${colors.white()};
          overflow: hidden;
          margin: 0 auto;

          width: calc(100% - 2px);

          @media (min-aspect-ratio: 16/9) {
            width: calc(100vh * 16 / 9 - 2px);
          }

          @media (min-aspect-ratio: 21/9) {
            --h1: calc(${this.clamp(this.size.h1, 0.7)});
            --h2: calc(${this.clamp(this.size.h2, 0.7)});
            --h3: calc(${this.clamp(this.size.h3, 0.7)});
            --p: calc(${this.clamp(this.size.p, 0.7)});
            --li: calc(${this.clamp(this.size.li, 0.7)});
            --lis: calc(${this.clamp(this.size.lis, 0.7)});
            --pad: calc(${this.clamp(this.size.pad, 0.7)});
            --sep: calc(${this.clamp(this.size.sep, 0.7)});
          }

          @media all and (display-mode: fullscreen) {
            width: calc(100% - 4px);
            @media (min-aspect-ratio: 16/9) {
              width: calc(100vh * 16 / 9 - 4px);
            }
          }
        `
      : css`
          ${this.variables(fullView)}
          ${this.dpCodeCommon(showCodeOutput, showCodeFigure)}

          position: relative;
          border-radius: ${this.borderRadius};
          border: 1px solid ${colors.grey()};
          background-color: ${colors.white()};
          overflow: hidden;

          width: calc(100% - 2px);
        `;

  cssContentRoot = (color: string, background: string) =>
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; /* << important */
      overflow: hidden; /* << important */

      color: ${color};
      ${background};

      tr:nth-of-type(even) {
        background: rgba(0, 0, 0, 0.15);
      }
      code {
        border-radius: ${this.borderRadius};
      }

      h1 {
        font-size: var(--h1);
      }
      h2 {
        font-size: var(--h2);
      }
      h3 {
        font-size: var(--h3);
      }
      li {
        font-size: var(--li);
        margin: 0 0 var(--lis) 0;
      }
      p {
        font-size: var(--p);
      }
      code {
        font-size: var(--p);
      }
      th {
        font-size: var(--p);
      }
      td {
        font-size: var(--p);
      }
    `;

  private dpImgCommon = (imgHeightPct = 100) =>
    `.dp-img { width: 100%; height: ${imgHeightPct}%; object-fit: contain; }`;

  private dpTitleCommon = `
    .dp-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        margin: 0 var(--pad);
      }
    }`;

  private dpBottomCommon = `
    .dp-bottom {
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
    }
  `;

  private dpCenCommon = `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

  private dpCol2Common = (imgWidthPct = 60) => `
    .dp-left {
      position: absolute;
      top: 20%;
      left: var(--pad);
      right: calc(${100 - imgWidthPct}% + var(--sep) / 2);
      bottom: 20%;
      ${this.dpCenCommon}
    }
    .dp-right {
      position: absolute;
      top: 20%;
      left: calc(${imgWidthPct}% + var(--sep) / 2);
      right: var(--pad);
      bottom: 20%;
      ${this.dpCenCommon}
    }
  `;

  cssContentWrapper = (layout: SLayout, imgWidthPct?: number, imgHeightPct?: number) =>
    layout === 'row1'
      ? css`
          /* row 1 */
          ${this.dpImgCommon(imgHeightPct)}
          .dp-left {
            position: absolute;
            top: 0;
            left: var(--pad);
            right: var(--pad);
            bottom: 0;
            ${this.dpCenCommon}
          }
        `
      : layout === 'row2col1'
      ? css`
          /* row2col1 */
          ${this.dpImgCommon(imgHeightPct)}
          ${this.dpTitleCommon};
          .dp-left {
            position: absolute;
            top: 20%;
            left: var(--pad);
            right: var(--pad);
            bottom: 15%;
            ${this.dpCenCommon}
          }
        `
      : layout === 'row2col2'
      ? css`
          /* row2col2 */
          ${this.dpImgCommon(imgHeightPct)}
          ${this.dpTitleCommon}
          ${this.dpCol2Common(imgWidthPct)}
        `
      : layout === 'row3col1'
      ? css`
          /* row3col1 */
          ${this.dpImgCommon(imgHeightPct)}
          ${this.dpTitleCommon}
          ${this.dpBottomCommon}
          .dp-left {
            position: absolute;
            top: 20%;
            left: var(--pad);
            right: var(--pad);
            bottom: 20%;
            ${this.dpCenCommon}
          }
        `
      : layout === 'row3col2'
      ? css`
          /* row3col2 */
          ${this.dpImgCommon(imgHeightPct)}
          ${this.dpTitleCommon}
          ${this.dpBottomCommon};
          ${this.dpCol2Common(imgWidthPct)}
        `
      : css`
          /* nofig */
          padding: 0 var(--pad);
        `;

  cssPopupRoot = () => css`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--p);
  `;

  cssPopupWrapper = () => css`
    padding: 0 var(--pad);
    border-bottom: 1px solid ${colors.grey()};
    color: ${colors.grey(25)};
  `;

  cssPopupOutput = () => css`
    padding: 0 var(--pad);
  `;
}

export const slide = new Slide();

/*
const fontStyle = {
  cursive: css`
    color: #f4f1de;
    font-family: 'Sniglet', cursive;
    text-shadow: #863027 0.01em 0.01em 0, #863027 0.02em 0.02em 0, #863027 0.03em 0.03em 0,
      #863027 0.04em 0.04em 0, #863027 0.05em 0.05em 0, #863027 0.06em 0.06em 0,
      #863027 0.07em 0.07em 0, #863027 0.08em 0.08em 0, #863027 0.09em 0.09em 0,
      #863027 0.1em 0.1em 0;
  `,
};
*/
