import { camelize } from '@cpmech/basic';
import { IManifestItem } from '../data';

export const getCourseLabel = (mdKey: string) => {
  if (mdKey.startsWith('civl')) {
    const label = mdKey.slice(9);
    if (label.startsWith('tut') || label.startsWith('lec')) {
      const key = label.slice(0, 5).replace(/^./, (m) => m.toUpperCase());
      return `${key} ${camelize(label.slice(6), true)}`;
    }
  }
  return mdKey;
};

export const getMediaItemLabel = (item: IManifestItem) => {
  const maxChars = 32;
  if (item.title.length > maxChars) {
    return item.title.slice(0, maxChars) + '...';
  }
  return item.title;
};
