import { useState, useEffect } from 'react';
import { store } from '../service';

interface IUseStoreState {
  anyError: string;
  loadManifestError: string;
  loadManifestCompleted: boolean;
  loadSearchIndexCompleted: boolean;
  loadResourceUrlInProgress: boolean;
  updateMediaItemInProgress: boolean;
  updateCustomerInProgress: boolean;
  updateSearchResultsInProgress: boolean;
}

const makeUseStoreState = (): IUseStoreState => ({
  anyError: store.getFirstError(),
  loadManifestError: store.actions.loadManifest.error,
  loadManifestCompleted: store.actions.loadManifest.successCount > 0,
  loadSearchIndexCompleted: store.actions.loadSearchIndex.successCount > 0,
  loadResourceUrlInProgress: store.actions.loadResourceUrl.inProgress,
  updateMediaItemInProgress: store.actions.updateMediaItem.inProgress,
  updateCustomerInProgress: store.actions.updateCustomer.inProgress,
  updateSearchResultsInProgress: store.actions.updateSearchResults.inProgress,
});

export const useStore = (observerName: string) => {
  const [data, setData] = useState<IUseStoreState>({
    anyError: '',
    loadManifestError: '',
    loadManifestCompleted: false,
    loadSearchIndexCompleted: false,
    loadResourceUrlInProgress: false,
    updateMediaItemInProgress: false,
    updateCustomerInProgress: false,
    updateSearchResultsInProgress: false,
  });

  useEffect(() => {
    // flag to prevent calling setData when the component is unmounted
    let finished = false;

    // must set the state right here and right now because the login/signup
    // may have been already configured and we missed the notification
    setData(makeUseStoreState());

    // now we can listen to further notifications, if any
    const unsubscribe = store.subscribe(() => {
      if (!finished) {
        setData(makeUseStoreState());
      }
    }, observerName);

    // return clean-up function
    return () => {
      finished = true;
      unsubscribe();
    };

    // note dependencies
  }, [observerName]);

  return data;
};
