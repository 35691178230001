export interface ICustomer {
  itemId: string; // userId: same as Cognito's username
  aspect: 'CUSTOMER';
  indexSK: string; // createdAt
  email: string;

  fullName?: string;
  studentNumber?: string;
  accessGroups?: string;
}

export interface ICustomerInputQue {
  itemId: string;
}

export interface ICustomerInputMut {
  itemId: string;
  fullName?: string;
  studentNumber?: string;
}

export interface ICustomerQueries {
  getCustomer: (input: ICustomerInputQue) => Promise<ICustomer | null>;
  xGetCustomers: () => Promise<ICustomer[]>;
}

export interface ICustomerMutations {
  setCustomer: (input: ICustomerInputMut) => Promise<ICustomer | null>;
}

export const newZeroCustomer = (): ICustomer => ({
  itemId: '',
  aspect: 'CUSTOMER',
  indexSK: '',
  email: '',

  fullName: '',
  studentNumber: '',
  accessGroups: '',
});

export const refCustomer: ICustomer = {
  itemId: '',
  aspect: 'CUSTOMER',
  indexSK: '',
  email: '',

  fullName: '',
  studentNumber: '',
  accessGroups: '',
};

export const optCustomer = {
  fullName: true,
  studentNumber: true,
  accessGroups: true,
};

export const refCustomerInputQue: ICustomerInputQue = {
  itemId: '',
};

export const refCustomerInputMut: ICustomerInputMut = {
  itemId: '',
  fullName: '',
  studentNumber: '',
};

export const optCustomerInputMut = {
  fullName: true,
  studentNumber: true,
};
