/** @jsxImportSource @emotion/react */
import { Fragment, ReactNode, useState } from 'react';
import { RcReadyOrErrorPopup } from '../../rcomps';
import { control, store } from '../../service';
import { styles } from '../../styles';
import { DrawingPad } from './DrawingPad';
import { LaserPointer } from './LaserPointer';
import { Toolbar } from './Toolbar';
import { useControl, useNav } from '../../hooks';
import { SLayout } from '../../styles/slide';
import { getImgSize, metadata2colors, regexps } from '../../util';

export interface SlidesProps {
  mdKey: string;
  idxSlide: number;
}

export const Slides: React.FC<SlidesProps> = ({ mdKey, idxSlide }) => {
  const { fullView } = useControl('Slide');
  const [state, setState] = useState({
    showCodeFig: false,
    showCodeOut: false,
    showDraw: false,
    showLaserPointer: false,
  });
  const { goto, gohome } = useNav();

  const presentation = store.getMediaItem('PRESENTATION', mdKey);

  if (!presentation) {
    return null;
  }

  const slides = presentation.htmls;

  if (slides.length < 1) {
    return (
      <RcReadyOrErrorPopup ready={false} error="There are no slides to display" onClose={gohome} />
    );
  }

  if (idxSlide < 0 || idxSlide >= slides.length) {
    return <RcReadyOrErrorPopup ready={false} error="Slide number is invalid" onClose={gohome} />;
  }

  const slide = presentation.htmls[idxSlide].html;

  if (!slide) {
    return null;
  }

  const hasCodeFig = slide.match(regexps.codeImg) !== null;
  const hasCodeOut = slide.match(regexps.codeOut) !== null;
  const { color, background } = control.state.printModeView
    ? { color: '#343434', background: '#ffffff' }
    : metadata2colors(idxSlide, slides.length, slide, presentation.metadata);

  const goPrev = () => {
    if (idxSlide > 0) {
      const newIdxSlide = idxSlide - 1;
      setState({
        ...state,
        showCodeFig: false,
        showCodeOut: false,
      });
      goto({ route: 'presentation', sub: presentation.mdKey, id: `${newIdxSlide + 1}` });
    }
  };

  const goNext = () => {
    if (idxSlide < slides.length - 1) {
      const newIdxSlide = idxSlide + 1;
      setState({
        ...state,
        showCodeFig: false,
        showCodeOut: false,
      });
      goto({ route: 'presentation', sub: presentation.mdKey, id: `${newIdxSlide + 1}` });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      goPrev();
    }
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      goNext();
    }
  };

  const imgArr = slide.match(regexps.img);
  const image = imgArr ? imgArr[0] : '';
  let top: ReactNode = null;
  let left: ReactNode = null;
  let right: ReactNode = null;
  let bottom: ReactNode = null;
  let layout: SLayout = 'nofig';
  let imgWidthPct: number | undefined;
  let imgHeightPct: number | undefined;
  if (image) {
    const size = getImgSize(image);
    imgWidthPct = size.width;
    imgHeightPct = size.height;
    const h2Arr = slide.match(regexps.h2);
    const ulArr = slide.match(regexps.ul);
    let rest = slide
      .replace(regexps.fig, '')
      .replace(regexps.h2, '')
      .replace(regexps.ul, '')
      .replace(regexps.newLines, '')
      .replace(regexps.emptyP, '');
    const title = h2Arr ? h2Arr[0] : '';
    let text = ulArr ? ulArr[0] : '';
    const pps = rest.match(regexps.p);
    if (pps && pps.length > 1) {
      text = rest;
      rest = '';
    }
    if (title && text && rest) {
      layout = 'row3col2';
      top = <div className="dp-title" dangerouslySetInnerHTML={{ __html: title }} />;
      left = <div className="dp-left" dangerouslySetInnerHTML={{ __html: image }} />;
      right = <div className="dp-right" dangerouslySetInnerHTML={{ __html: text }} />;
      bottom = <div className="dp-bottom" dangerouslySetInnerHTML={{ __html: rest }} />;
    } else if (title && rest) {
      layout = 'row3col1';
      top = <div className="dp-title" dangerouslySetInnerHTML={{ __html: title }} />;
      left = <div className="dp-left" dangerouslySetInnerHTML={{ __html: image }} />;
      bottom = <div className="dp-bottom" dangerouslySetInnerHTML={{ __html: rest }} />;
    } else if (title && text) {
      layout = 'row2col2';
      top = <div className="dp-title" dangerouslySetInnerHTML={{ __html: title }} />;
      left = <div className="dp-left" dangerouslySetInnerHTML={{ __html: image }} />;
      right = <div className="dp-right" dangerouslySetInnerHTML={{ __html: text }} />;
    } else if (title) {
      layout = 'row2col1';
      top = <div className="dp-title" dangerouslySetInnerHTML={{ __html: title }} />;
      left = <div className="dp-left" dangerouslySetInnerHTML={{ __html: image }} />;
    } else {
      layout = 'row1';
      left = <div className="dp-left" dangerouslySetInnerHTML={{ __html: image }} />;
    }
  } else {
    left = <div dangerouslySetInnerHTML={{ __html: slide }} />;
  }

  return (
    <div
      css={styles.slide.cssMain(fullView, state.showCodeOut, state.showCodeFig)}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      <div css={styles.cascade.viewport}>
        <div css={styles.cascade.absoluteZeroNoOverflow}>
          <div css={styles.slide.cssContentRoot(color, background)}>
            <div css={styles.slide.cssContentWrapper(layout, imgWidthPct, imgHeightPct)}>
              <Fragment>
                {top}
                {left}
                {right}
                {bottom}
              </Fragment>
            </div>
          </div>
        </div>
      </div>

      {state.showDraw && <DrawingPad />}

      {state.showLaserPointer && <LaserPointer />}

      <Toolbar
        goPrev={goPrev}
        goNext={goNext}
        toggleCodeFig={() => setState({ ...state, showCodeFig: !state.showCodeFig })}
        toggleCodeOut={() => setState({ ...state, showCodeOut: !state.showCodeOut })}
        toggleDraw={() => setState({ ...state, showDraw: !state.showDraw })}
        toggleLaserPointer={() => setState({ ...state, showLaserPointer: !state.showLaserPointer })}
        idxSlide={idxSlide}
        numSlides={slides.length}
        hasCodeFig={hasCodeFig}
        showCodeFig={state.showCodeFig}
        hasCodeOut={hasCodeOut}
        showCodeOut={state.showCodeOut}
        showDraw={state.showDraw}
        showLaserPointer={state.showLaserPointer}
      />
    </div>
  );
};
