export const regexps = {
  codeOut: new RegExp(/<div.*class="dp-code-out".*?[^>]+>/),
  codeImg: new RegExp(/<img.*class="dp-code-img".*?[^>]+>/),
  fig: new RegExp(/<div.*class="dp-fig".*?[^>]+>[\s\S]*?<\/div>/),
  img: new RegExp(/<img.*class="dp-img".*?[^>]+>/),
  ul: new RegExp(/<ul>[\s\S]*?<\/ul>/),
  h2: new RegExp(/<h2>[\s\S]*?<\/h2>/),
  newLines: new RegExp(/\r?\n|\r/g),
  emptyP: new RegExp(/<p><\/p>/, 'mg'),
  p: new RegExp(/<p>[\s\S]*?<\/p>/, 'mg'),
  src: new RegExp(/src="([^"]+)"/),
};
