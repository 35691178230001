import { css } from '@emotion/react';
import { colors } from './colors';

export const article = {
  root: css`
    background-color: ${colors.white()};
    padding: 40px 20px;
    padding-bottom: 80px;

    .dp-fig {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 100%;
      background-color: transparent;
    }

    table {
      border-collapse: collapse;
      display: block;
      width: max-content;
      max-width: 100%;
      overflow: auto;
    }

    th {
      font-weight: bold;
      background-color: #793680;
      color: white;
      padding: 10px 13px;
      border: 1px solid #ddd;
    }

    td {
      padding: 6px 13px;
      border: 1px solid #ddd;
    }

    tr:nth-of-type(even) {
      background-color: #f2f2f2;
    }

    tr {
      background-color: #fff;
    }
  `,
};
