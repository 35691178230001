/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { Loading } from '../components';
import { useStore } from '../hooks';
import { RcLinkOrDiv } from '../rcomps';
import { resources, store } from '../service';
import { styles } from '../styles';

const styleLi = css`
  margin: 0 0 12px 0;
`;

const styleWeek = css`
  margin: 50px 0;
`;

export interface ResourcesPageProps {
  course: string;
}

export const ResourcesPage: React.FC<ResourcesPageProps> = ({ course }) => {
  const { loadResourceUrlInProgress } = useStore('ResourcesPage');

  const res = resources[course];
  if (!res) {
    console.log('not found', course);
    return null;
  }

  const comps = Object.keys(res).reduce((acc, curr) => {
    const items = res[curr].map((d, i) => (
      <li key={i} css={styleLi}>
        <RcLinkOrDiv
          color={styles.colors.blue()}
          hoverColor={styles.colors.blue(50)}
          cssExtra=":hover { text-decoration: underline; }"
          onClick={() => store.loadResourceUrl(course, d.filename, (url) => window.open(url))}
        >
          <b>{`${d.author} (${d.year})`}</b> {d.title}
        </RcLinkOrDiv>
      </li>
    ));
    return [
      ...acc,
      <div key={curr} css={styleWeek}>
        <h3>{curr}</h3>
        <ol>{items}</ol>
      </div>,
    ];
  }, [] as ReactNode[]);

  return (
    <div
      css={css`
        background-color: #ffffff;
        margin: 60px 20px;
        margin-bottom: 100px;
        font-size: 1.3em;
      `}
    >
      <h2>{course.toUpperCase()} ADDITIONAL RESOURCES</h2>
      {comps}
      {loadResourceUrlInProgress && <Loading />}
    </div>
  );
};
