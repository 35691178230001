/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Fragment } from 'react';

const welcome = (
  <Fragment>
    <h1>WELCOME</h1>
    <p>
      I am a Senior <a href="https://researchers.uq.edu.au/researcher/2057">Lecturer</a> at The University of Queensland (UQ) in Australia. Some of my keynote presentations is a prestigious <a href="https://www.simtech.uni-stuttgart.de/events/argyris/">2016 Argyris Lecture at the University of Stuttgart, Germany</a>.
    </p>

    <p>
      I have developed <a href="https://github.com/cpmech">open source projects</a>to assist in research work and papers. My code repositories include a comprehensive library for Scientific Computing (Gosl) and a newer library for Scientific Computing with Rust (Russell).
    </p>

    <p>
      I have experience with computer languages and frameworks, including:
    </p>

    <ul>
      <li>Fortran, C and modern C++ 17</li>
      <li>Python and R</li>
      <li>Go and Rust</li>
      <li>JavaScript and TypeScript</li>
      <li>React, React Native, and Vue</li>
      <li>High-performance computing (HPC)</li>
    </ul>

    <p>Few samples of scientific research using the above languages (<a href="https://scholar.google.com/citations?user=ZlBPmYEAAAAJ">More from Google Scholar</a>):</p>

    <ul>
      <li>Go: <a href="https://www.sciencedirect.com/science/article/abs/pii/S1568494617305458">2017 Multi-objective and multi-constraints optimization</a></li>
      <li>Go: <a href="https://www.sciencedirect.com/science/article/abs/pii/S0167473017300012">2017 Solution of structural reliability optimization problems</a></li>
      <li>Python: <a href="https://www.sciencedirect.com/science/article/abs/pii/S0266352X14001025">2014 Study of a technique for modelling soils</a></li>
      <li>R: <a href="https://www.sciencedirect.com/science/article/abs/pii/S0266352X08000104">2009 New modelling technique for soils</a></li>
      <li>C++: <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/nme.2407">2008 Solution of problems in elastoplasticity</a></li>
      <li>HPC: <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/nme.4808">2015 Solution of porous media problems 1</a></li>
      <li>HPC: <a href="https://www.sciencedirect.com/science/article/abs/pii/S0045782514004885">2015 Solution of porous media problems 2</a></li>
    </ul>

    <p>
      This website collects some of my presentations related to Civil Engineering and some of my research.
    </p>

    <p><b>About the logo:</b> The logo is an illustration of octahedral planes on the principal stress space. The arrow is aligned with the so-called hydrostatic axis.</p>

    <h2>TEACHING</h2>

    <p>
      I am currently teaching Advanced Soil Mechanics (CIVL4230) and Ground Improvement (CIVL6215) for undergraduate and ME students in Civil Engineering.
    </p>

    <p>See the menu on the left for more information.</p>

    <h2>RESEARCH</h2>

    <p>The figure belows shows the indentation of a porous cube.</p>

    <img src="fig/porous-media-indentation.jpg" alt="3D porous media indentation" width="100%" />

    <p>
      <a href="https://onlinelibrary.wiley.com/doi/10.1002/nme.4808">
        (Figure from: Pedroso, 2014. A consistent u-p formulation for porous media with hysteresis)
      </a>
    </p>

    <p>I am currently interested on the solution of thermo-hydraulic-chemical-mechanical (THCM) modelling of porous media behavior.</p>

    <p>To this end, I am developing a simulation framework using the <a href="https://www.rust-lang.org/">🦀 Rust language 🦀</a>.</p>

    <p>At the moment, I am developing the following 🦀 Rust libraries 🦀:</p>

    <ul>
      <li><a href="https://github.com/cpmech/russell">Russell</a> Rust Scientific Library</li>
      <li><a href="https://github.com/cpmech/plotpy">Plotpy</a> Rust plotting library using Python (Matplotlib)</li>
      <li><a href="https://github.com/cpmech/gemlab">Gemlab</a> Geometry, meshes, and numerical integration for finite element analyses</li>
      <li><a href="https://github.com/cpmech/pmsim">Pmsim</a> Porous media simulator using the finite element method</li>
    </ul>
  </Fragment>
);

const signin = (
  <Fragment>
    <h1>ACCESS GRANTED</h1>
    <p>Thanks for signing up!</p>
    <p style={{ fontSize: 40 }}>😀</p>
  </Fragment>
);

export interface HomePageProps {
  signInSuccessful?: boolean;
}

export const HomePage: React.FC<HomePageProps> = ({ signInSuccessful }) => {
  return (
    <div
      css={css`
        background-color: #ffffff;
        margin: 60px 20px;
        font-size: 1.3em;
      `}
    >
      {signInSuccessful ? signin : welcome}
    </div>
  );
};
