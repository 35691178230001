/** @jsxImportSource @emotion/react */
import { camelize } from '@cpmech/basic';
import { css } from '@emotion/react';
import { useStore } from '../hooks';
import { RcSpinCircle } from '../rcomps';
import { store } from '../service';
import { styles } from '../styles';
import { MenuLink } from './MenuLink';

const route2label = (route: string): string => {
  const list = route.split('/');
  if (list.length < 3) {
    return route;
  }
  const mdKey = list[2];
  const parts = mdKey.split('-');
  if (list.length > 3) {
    parts.push(list[3]);
  }
  return parts.map((d) => camelize(d, true).replace('Civl', 'CIVL')).join(' ');
};

export interface SearchResultsProps {
  onMenu: boolean;
}

export const SearchResults: React.FC<SearchResultsProps> = ({ onMenu }) => {
  const { updateSearchResultsInProgress } = useStore('SearchResults');

  if (updateSearchResultsInProgress) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
        `}
      >
        <RcSpinCircle size={styles.dims.icon.medium} thickness="3px" />
      </div>
    );
  }

  return (
    <div>
      {store.state.searchResults.map((route, i) => (
        <div
          key={i}
          css={css`
            margin: 10px 10px;
          `}
        >
          <MenuLink
            onMenu={onMenu}
            label={route2label(route)}
            route={route}
            fontSize={'80%'}
            matchSlideIndex={true}
          />
        </div>
      ))}
    </div>
  );
};
