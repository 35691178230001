/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IconBackspace } from '@cpmech/iricons/IconBackspace';
import { IconSearch } from '@cpmech/iricons/IconSearch';
import { rcConfig, RcInput, RcLinkOrDiv } from '../rcomps';
import { store } from '../service';
import { styles } from '../styles';
import { useStore } from '../hooks';

const lnkDivProps = {
  color: styles.colors.white(),
  hoverColor: styles.colors.grey(50),
};

export const SearchBar: React.FC = () => {
  const { loadSearchIndexCompleted } = useStore('SearchBar');
  const isNarrow = useMediaQuery({ maxWidth: rcConfig.media.phone.maxWidth });
  const [sentence, setSentence] = useState(store.state.searchSentence);
  const ref = useRef<HTMLInputElement | null>(null);

  const setRef = useCallback((node: HTMLInputElement) => {
    ref.current = node;
  }, []);

  useEffect(() => {
    store.loadSearchIndex();
    const onKeyUp = (ev: KeyboardEvent) => {
      if (ev.altKey && ev.key === 's') {
        if (ref.current) {
          ref.current.focus();
        }
      }
    };
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  if (!loadSearchIndexCompleted) {
    return null;
  }

  const onSearch = () => {
    store.search(sentence);
  };

  const onClearSearch = () => {
    store.clearSearchResults();
    setSentence('');
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          margin-right: 10px;
        `}
      >
        <RcLinkOrDiv onClick={onSearch} {...lnkDivProps}>
          <IconSearch size={styles.dims.icon.medium} />
        </RcLinkOrDiv>
      </div>
      <div>
        <RcInput
          inputRef={setRef}
          label={isNarrow ? undefined : 'Search [Alt+S]'}
          value={sentence}
          onChange={(e) => setSentence(e.target.value)}
          onEnterKeyUp={onSearch}
          borderRadius={styles.dims.input.borderRadius}
          height={styles.dims.input.height}
          width="90%"
          hlColor={styles.colors.white()}
          mutedColor={styles.colors.grey(75)}
          borderColor={styles.colors.white()}
          color={styles.colors.white()}
          bgColor="rgba(0,0,0,0)"
          marginVert="0px"
          scaleLabel={0}
          suffix={
            sentence ? (
              <RcLinkOrDiv onClick={onClearSearch} {...lnkDivProps}>
                <IconBackspace size="18px" />
              </RcLinkOrDiv>
            ) : undefined
          }
        />
      </div>
    </div>
  );
};
