import axios from 'axios';
import lunr from 'lunr';
import { hasProp, Iany } from '@cpmech/basic';
import { checkType } from '@cpmech/js2ts';
import { SimpleStore } from '@cpmech/simple-state';
import {
  MediaKind,
  ICustomer,
  IMediaItem,
  IManifestItem,
  IManifest,
  IAllMedia,
  refMediaItem,
  newZeroManifest,
  newZeroAllMedia,
  refManifest,
  refCustomer,
  optCustomer,
} from '../data';
import { regexps, sanitizeHtml } from '../util';
import { config } from './config';
import { gate } from './gate';

type Action =
  | 'loadManifest'
  | 'loadSearchIndex'
  | 'loadResourceUrl'
  | 'updateMediaItem'
  | 'updateCustomer'
  | 'updateSearchResults';

const actionNames: Action[] = [
  'loadManifest',
  'loadSearchIndex',
  'loadResourceUrl',
  'updateMediaItem',
  'updateCustomer',
  'updateSearchResults',
];

// define the state interface
interface IState {
  customer: ICustomer | null;
  manifest: IManifest;
  allMedia: IAllMedia;
  searchSentence: string;
  searchResults: string[];
  images: { [key: string]: HTMLImageElement };
}

// define a function to generate a blank state
const newZeroState = (): IState => ({
  customer: null,
  manifest: newZeroManifest(),
  allMedia: newZeroAllMedia(),
  searchSentence: '',
  searchResults: [],
  images: {},
});

// extend the SimpleStore class; it may have any additional members
class Store extends SimpleStore<Action, IState, null> {
  constructor(private wss: WebSocket | null = null, private searchIndex: lunr.Index | null = null) {
    super(actionNames, newZeroState);

    // setup WebSocket for local development
    if (window.location.hostname === 'localhost') {
      // new WebSocket
      this.wss = new WebSocket('ws://localhost:4444');

      // handle open websocket connection
      this.wss.onopen = (_) => {
        if (this.wss) {
          this.wss.send(JSON.stringify({ isGreetings: true, message: 'Hello from Frontend' }));
        }
      };

      // handle message
      this.wss.onmessage = (event) => {
        const json = JSON.parse(event.data);
        const { mediaItem, relDirname } = json;
        const mItem = checkType(refMediaItem, mediaItem);
        if (!mItem || !relDirname || typeof relDirname !== 'string') {
          return;
        }
        this.updateState('updateMediaItem', async () => {
          const { kind, mdKey } = mItem;
          const mdb = this.state.manifest[kind];
          if (!hasProp(mdb, mdKey)) {
            mdb[mdKey] = {
              accessGroup: 'all',
              relDirname,
              mdKey,
              title: mItem.title,
            };
          }
          this.state.allMedia[kind][mdKey] = mItem;
        });
      };
    }
  }

  // setters //////////////////////////////////////////////////////////////////////////

  loadManifest = async () => {
    this.updateState('loadManifest', async () => {
      const { data } = await axios.get(`/mediaManifest.json`);
      if (data) {
        const manifest = checkType(refManifest, data);
        if (manifest) {
          this.state.manifest = manifest;
        }
      }
    });
  };

  loadSearchIndex = async () => {
    this.updateState('loadSearchIndex', async () => {
      const { data } = await axios.get(`/searchIndex.json`);
      if (data) {
        this.searchIndex = lunr.Index.load(data);
      }
    });
  };

  loadMediaItem = async (kind: MediaKind, mdKey: string, forceReload = false) => {
    this.updateState('updateMediaItem', async () => {
      if (!forceReload && this.hasMediaItem(kind, mdKey)) {
        return;
      }
      await this.doLoadMediaItem(kind, mdKey);
    });
  };

  loadResourceUrl = async (course: string, filename: string, callback: (url: string) => void) => {
    this.updateState('loadResourceUrl', async () => {
      const { data } = await this.callApi({
        query: 'getDownloadUrl',
        input: { path: `/resources/${course}/${filename}` },
      });
      callback(data);
    });
  };

  loadCustomer = async () => {
    this.updateState('updateCustomer', async () => {
      const { data } = await this.callApi({
        query: 'getCustomer',
        input: { itemId: gate.user.username },
      });
      const customer = checkType(refCustomer, data, optCustomer);
      if (!customer) {
        throw new Error('Cannot load data from server. Undefined value.');
      }
      this.state.customer = customer;
    });
  };

  saveStudentNumber = async (studentNumber: string) => {
    this.updateState('updateCustomer', async () => {
      const { data } = await this.callApi({
        mutation: 'setCustomer',
        input: { itemId: gate.user.username, studentNumber },
      });
      const customer = checkType(refCustomer, data, optCustomer);
      if (!customer) {
        throw new Error('Cannot save data in server. Undefined value.');
      }
      this.state.customer = customer;
    });
  };

  search = async (sentence: string) => {
    this.updateState('updateSearchResults', async () => {
      if (sentence && this.searchIndex) {
        const words = sentence
          .split(' ')
          .map((d) => '+' + d)
          .join(' ');
        const res = this.searchIndex.search(words);
        res.sort((a, b) => {
          const l = a.ref.split('/').slice(1);
          const r = b.ref.split('/').slice(1);
          if (l.length < r.length) {
            return -1;
          }
          if (r.length < l.length) {
            return +1;
          }
          if (l.length < 2) {
            return 0;
          }
          if (l[0] !== r[0]) {
            return l[0] < r[0] ? -1 : +1;
          }
          if (l[1] !== r[1]) {
            return l[1] < r[1] ? -1 : +1;
          }
          if (l.length > 2) {
            const delta = Number(l[2]) - Number(r[2]);
            return delta;
          }
          return 0;
        });
        this.state.searchSentence = sentence;
        this.state.searchResults = res.map((d) => d.ref).slice(0, 20);
      } else {
        this.state.searchSentence = '';
        this.state.searchResults = [];
      }
    });
  };

  clearSearchResults = async () => {
    this.updateState('updateSearchResults', async () => {
      this.state.searchSentence = '';
      this.state.searchResults = [];
    });
  };

  // getters //////////////////////////////////////////////////////////////////////////

  hasStudentNumber = (): boolean => true;
  /* DISABLE USE OF STUDENT NUMBER
    (this.state.customer &&
      this.state.customer?.studentNumber?.length &&
      this.state.customer?.studentNumber?.length > 4) ||
    false;
  */

  getStudentNumber = (): string => (this.state.customer && this.state.customer.studentNumber) || '';

  getMediaItem = (kind: MediaKind, mdKey: string): IMediaItem | null =>
    this.hasMediaItem(kind, mdKey) ? this.state.allMedia[kind][mdKey] : null;

  isOpen = (kind: MediaKind, mdKey: string): boolean => {
    if (hasProp(this.state.manifest[kind], mdKey)) {
      return this.state.manifest[kind][mdKey].accessGroup === 'all';
    }
    return false;
  };

  getManifests = (
    kind: MediaKind,
    filter: (mdKey: string, link?: string) => boolean,
  ): IManifestItem[] =>
    Object.keys(this.state.manifest[kind])
      .filter((mdKey) => filter(mdKey, this.state.manifest[kind][mdKey].link))
      .sort((a, b) => a.localeCompare(b))
      .map((mdKey) => this.state.manifest[kind][mdKey]);

  getTitle = (kind: MediaKind, mdKey: string): string => {
    const item = this.state.manifest[kind][mdKey];
    if (item) {
      return item.title;
    }
    return mdKey;
  };

  getCourses = () => this.getManifests('ARTICLE', this.isCourse).map((d) => d.mdKey);

  getCourseLectureManifests = (course: string) =>
    this.getManifests('PRESENTATION', this.sameCourse(course, 'lec'));

  getCourseTutorialManifests = (course: string) =>
    this.getManifests('PRESENTATION', this.sameCourse(course, 'tut'));

  getCourseArticleManifests = (course: string) => this.getManifests('ARTICLE', this.linked(course));

  getNotCourseArticleManifests = () => this.getManifests('ARTICLE', this.notRelatedToCourse);

  getNotCoursePresentationManifests = () =>
    this.getManifests('PRESENTATION', this.notRelatedToCourse);

  // private //////////////////////////////////////////////////////////////////////////

  private hasMediaItem = (kind: MediaKind, mdKey: string): boolean =>
    hasProp(this.state.allMedia[kind], mdKey);

  private isCourse = (mdKey: string) => mdKey.startsWith('civl');

  private notRelatedToCourse = (mdKey: string, link?: string) =>
    !(mdKey.startsWith('civl') || link?.startsWith('civl') || false);

  private sameCourse = (aMdKey: string, filter: 'lec' | 'tut') => (bMdKey: string) =>
    bMdKey.startsWith(aMdKey) && bMdKey.includes(filter);

  private linked = (aMdKey: string) => (_: string, link?: string) =>
    link ? link === aMdKey : false;

  private callApi = async (queryOrMutation: Iany): Promise<any> => {
    const options = config.isLocalApi
      ? { headers: { 'x-data-x': `${gate.user.username},${gate.user.email},customers` } }
      : { headers: { Authorization: `Bearer ${gate.user.idToken}` } };
    return await axios.post(`${config.apiUrl}graphql`, queryOrMutation, options);
  };

  private doLoadMediaItem = async (kind: MediaKind, mdKey: string) => {
    const { accessGroup, relDirname } = this.state.manifest[kind][mdKey];
    const path = `${relDirname}/${mdKey}.json`;
    const isOpen = accessGroup === 'all';
    const { data } = isOpen
      ? await axios.get(path)
      : await this.callApi({ query: 'getJson', input: { path } });
    const json = checkType(refMediaItem, data);
    if (!json) {
      throw new Error(`cannot load ${kind} item with mdKey = ${mdKey}`);
    }
    for (let i = 0; i < json.htmls.length; i++) {
      json.htmls[i].html = sanitizeHtml(json.htmls[i].html);
      const imgArr = json.htmls[i].html.match(regexps.img);
      if (imgArr) {
        imgArr.forEach((img) => {
          const res = img.match(regexps.src);
          if (res) {
            const image = new Image();
            image.src = res[1];
            this.state.images[`${mdKey}#${res[1]}`] = image;
          }
        });
      }
    }
    this.state.allMedia[kind][mdKey] = json;
  };
}

// instantiate store
export const store = new Store();

// load manifest
store.loadManifest();
