/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconClose } from '@cpmech/iricons/IconClose';
import { rcDefaultMenuOptions, RcLinkOrDiv, RcMenuEntry, RcMenuHoriz } from '../rcomps';
import { styles } from '../styles';
import { control } from '../service';

export interface WarningProps {}

export const Warning: React.FC<WarningProps> = () => {
  const menuEntries: RcMenuEntry[] = [
    {
      comp: (
        <div
          css={css`
            color: ${styles.colors.white()};
          `}
        >
          TODO
        </div>
      ),
    },
    {
      comp: (
        <RcLinkOrDiv onClick={() => control.setShowWarning(false)} color={styles.colors.white()}>
          <IconClose size={styles.dims.icon.medium} />
        </RcLinkOrDiv>
      ),
    },
  ];

  return (
    <div
      css={css`
        background-color: ${styles.colors.red(0)};
        height: ${styles.dims.warning.height};
      `}
    >
      <RcMenuHoriz
        entries={menuEntries}
        height={styles.dims.warning.height}
        marginTop={'0px'}
        paddingVert={'0px'}
        options={rcDefaultMenuOptions({
          colorLabel: styles.colors.white(),
          paddingHoriz: '20px',
          maxWidth: `${styles.dims.minMaxPageWidth}px`,
          bgColor: styles.colors.red(0),
        })}
      />
    </div>
  );
};
