/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RcReadyOrErrorPopup } from '../../rcomps';
import { control, store } from '../../service';
import { Slides } from './Slides';

const stylePage = css`
  padding: 20px 20px;
  max-width: calc(100vw - 40px);
  overflow-x: hidden;
  .dp-code-out {
    code {
      border: 1px dashed #484848;
    }
  }
  .dp-code-fig {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dp-code-img {
    max-height: 50%;
    max-width: 50%;
    object-fit: contain;
  }
  .dp-fig {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
  }
  .dp-img {
    max-width: 100%;
    max-height: 250px;
    object-fit: contain;
  }
`;

const styleNoFigBreak = css`
  /* prevent breaking images */
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
`;

const styleAllSlidesPrint = css`
  width: 85%;
  margin: 0 auto;
`;

export interface SlidesPrintModeProps {
  mdKey: string;
}

export const SlidesPrintMode: React.FC<SlidesPrintModeProps> = ({ mdKey }) => {
  const presentation = store.getMediaItem('PRESENTATION', mdKey);

  if (!presentation) {
    return null;
  }

  const slides = presentation.htmls;

  if (slides.length < 1) {
    return (
      <RcReadyOrErrorPopup
        ready={false}
        error="There are no slides to display"
        onClose={() => console.log(`nav('/')`)}
      />
    );
  }

  if (control.state.allSlidesView) {
    return (
      <div css={control.state.printModeView ? styleAllSlidesPrint : ''}>
        {slides.map((_, i) => (
          <div key={i}>
            <div css={styleNoFigBreak}>
              <Slides mdKey={mdKey} idxSlide={i} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div css={stylePage}>
      {slides.map((slide, i) => (
        <div key={i}>
          <div css={styleNoFigBreak}>
            <div dangerouslySetInnerHTML={{ __html: slide.html }}></div>
          </div>
        </div>
      ))}
    </div>
  );
};
