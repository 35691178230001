/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconArrowBack } from '@cpmech/iricons/IconArrowBack';
import { IconArrowForward } from '@cpmech/iricons/IconArrowForward';
import { IconContract } from '@cpmech/iricons/IconContract';
import { IconExpand } from '@cpmech/iricons/IconExpand';
import { IconBrush } from '@cpmech/iricons/IconBrush';
import { IconBrushOutline } from '@cpmech/iricons/IconBrushOutline';
import { IconEllipse } from '@cpmech/iricons/IconEllipse';
import { IconEllipseOutline } from '@cpmech/iricons/IconEllipseOutline';
import { IconImage } from '@cpmech/iricons/IconImage';
import { IconImageOutline } from '@cpmech/iricons/IconImageOutline';
import { IconReader } from '@cpmech/iricons/IconReader';
import { IconReaderOutline } from '@cpmech/iricons/IconReaderOutline';
import { RcLinkOrDiv } from '../../rcomps';
import { styles } from '../../styles';
import { control } from '../../service';
import { useControl } from '../../hooks';

export interface ToolbarProps {
  goPrev: () => void;
  goNext: () => void;
  toggleCodeFig: () => void;
  toggleCodeOut: () => void;
  toggleDraw: () => void;
  toggleLaserPointer: () => void;
  idxSlide: number;
  numSlides: number;
  hasCodeFig: boolean;
  showCodeFig: boolean;
  hasCodeOut: boolean;
  showCodeOut: boolean;
  showDraw: boolean;
  showLaserPointer: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  goPrev,
  goNext,
  toggleCodeFig,
  toggleCodeOut,
  toggleDraw,
  toggleLaserPointer,
  idxSlide,
  numSlides,
  hasCodeFig,
  showCodeFig,
  hasCodeOut,
  showCodeOut,
  showDraw,
  showLaserPointer,
}) => {
  const { fullView } = useControl('Toolbar');

  if (control.state.allSlidesView) {
    return (
      <div
        css={css`
          ${styles.toolbar.variables(fullView)}
        `}
      >
        <div css={styles.toolbar.cssBottomMiddle()}>
          <div>{`${idxSlide + 1} / ${numSlides}`}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      css={css`
        ${styles.toolbar.variables(fullView)}
      `}
    >
      {/* top-left */}
      <div css={styles.toolbar.cssTopLeft()}>
        <RcLinkOrDiv
          onClick={toggleLaserPointer}
          color={styles.toolbar.color}
          hoverColor={styles.toolbar.hoverColor}
        >
          {showLaserPointer ? <IconEllipseOutline size="100%" /> : <IconEllipse size="100%" />}
        </RcLinkOrDiv>
      </div>

      {/* top-right */}
      <div css={styles.toolbar.cssTopRight()}>
        <RcLinkOrDiv
          onClick={toggleDraw}
          color={styles.toolbar.color}
          hoverColor={styles.toolbar.hoverColor}
        >
          {showDraw ? <IconBrushOutline size="100%" /> : <IconBrush size="100%" />}
        </RcLinkOrDiv>
      </div>

      {/* bottom-left */}
      <div css={styles.toolbar.cssBottomLeft()}>
        <RcLinkOrDiv
          onClick={() => control.toggleFullView()}
          color={styles.toolbar.color}
          hoverColor={styles.toolbar.hoverColor}
        >
          {control.state.showHeader ? <IconExpand size="100%" /> : <IconContract size="100%" />}
        </RcLinkOrDiv>
      </div>

      {/* bottom-right */}
      <div css={styles.toolbar.cssBottomRight()}>
        <RcLinkOrDiv
          onClick={goNext}
          color={styles.toolbar.color}
          hoverColor={styles.toolbar.hoverColor}
        >
          <IconArrowForward size="100%" />
        </RcLinkOrDiv>
      </div>

      {/* bottom-right-1 */}
      <div css={styles.toolbar.cssBottomRight1()}>
        <RcLinkOrDiv
          onClick={goPrev}
          color={styles.toolbar.color}
          hoverColor={styles.toolbar.hoverColor}
        >
          <IconArrowBack size="100%" />
        </RcLinkOrDiv>
      </div>

      {/* right-1 */}
      {hasCodeFig && (
        <div css={styles.toolbar.cssRight1()}>
          <RcLinkOrDiv
            onClick={toggleCodeFig}
            color={styles.toolbar.color}
            hoverColor={styles.toolbar.hoverColor}
          >
            {showCodeFig ? <IconImageOutline size="100%" /> : <IconImage size="100%" />}
          </RcLinkOrDiv>
        </div>
      )}

      {/* left-1 */}
      {hasCodeOut && (
        <div css={styles.toolbar.cssLeft1()}>
          <RcLinkOrDiv
            onClick={toggleCodeOut}
            color={styles.toolbar.color}
            hoverColor={styles.toolbar.hoverColor}
          >
            {showCodeOut ? <IconReaderOutline size="100%" /> : <IconReader size="100%" />}
          </RcLinkOrDiv>
        </div>
      )}

      {/* bottom-middle */}
      <div css={styles.toolbar.cssBottomMiddle()}>
        <div>{`${idxSlide + 1} / ${numSlides}`}</div>
      </div>
    </div>
  );
};
