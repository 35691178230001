const rxWidth = new RegExp(/width="[\d]*?%"/);
const rxHeight = new RegExp(/height="[\d]*?%"/);
const rxNum = new RegExp(/\d+/);

export const getImgSize = (image: string): { width?: number; height?: number } => {
  const widthArr = image.match(rxWidth);
  let width: number | undefined = undefined;
  if (widthArr) {
    const val = widthArr[0].match(rxNum);
    if (val) {
      const w = Number(val[0]);
      if (w > 0 && w < 100) {
        width = w;
      }
    }
  }

  const heightArr = image.match(rxHeight);
  let height: number | undefined = undefined;
  if (heightArr) {
    const val = heightArr[0].match(rxNum);
    if (val) {
      const h = Number(val[0]);
      if (h > 0 && h < 100) {
        height = h;
      }
    }
  }

  return { width, height };
};
