/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RcModal, RcSpinDots } from '../../rcomps';

export const Loading: React.FC = () => {
  return (
    <RcModal marginVert="0px" verticalGap="0px" width="200px" paddingHoriz="0px">
      <div
        css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 100px;
        `}
      >
        <RcSpinDots size="48px" />
      </div>
    </RcModal>
  );
};
