/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { rcConfig } from '../../rcomps';
import { dims } from '../../styles/dims';

const style = css`
  --size: clamp(20px, 4vw, 64px);

  @media screen and (min-width: ${rcConfig.media.phone.maxWidth}px) {
    --size: clamp(20px, 3vw, 64px);
  }

  @media screen and (min-width: ${dims.minMaxPageWidth}px) {
    --size: 48px;
  }

  width: var(--size);
  height: var(--size);
  background: radial-gradient(closest-side, #d80c0c, #f4b209, #383ae6);
  opacity: 0.75;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
`;

export const LaserPointer: React.FC = () => {
  const ref = useRef<HTMLSpanElement>(null);
  const [coords, setCoords] = useState({ x: -666, y: -666 });

  useEffect(() => {
    let onMouseMove: ((ev: MouseEvent) => void) | null = null;
    if (ref.current) {
      const parent = ref.current.parentElement;
      if (parent) {
        onMouseMove = (ev: MouseEvent) => {
          if (ref.current) {
            const rect = parent.getBoundingClientRect();
            setCoords({ x: ev.pageX - rect.left, y: ev.pageY - rect.top });
          }
        };
        document.addEventListener('mousemove', onMouseMove);
      }
    }
    return () => {
      if (onMouseMove) {
        document.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [ref]);

  return <span ref={ref} css={style} style={{ left: coords.x, top: coords.y }}></span>;
};
