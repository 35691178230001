import { article } from './article';
import { cascade } from './cascade';
import { colors } from './colors';
import { dims } from './dims';
import { IFont, fonts } from './fonts';
import { laserpointer } from './laserpointer';
import { slide } from './slide';
import { themes } from './themes';
import { toolbar } from './toolbar';

export const styles = {
  article,
  cascade,
  colors,
  dims,
  fonts,
  laserpointer,
  slide,
  themes,
  toolbar,
  html: {
    get: (font: IFont = 'inter') => `
      html { margin: 0; }
      body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        ${fonts[font].cssFamily};
      }`,
  },
};
