import { withUseGateObserver } from '../gate';
import { useNav, useStore } from '../hooks';
import { gate, store } from '../service';
import { ArticlePage } from './ArticlePage';
import { ErrorPage } from './ErrorPage';
import { GoodbyePage } from './GoodbyePage';
import { HomePage } from './HomePage';
import { LegalPpPage } from './LegalPpPage';
import { LegalTsPage } from './LegalTsPage';
import { ListMediaItemsPage } from './ListMediaItemsPage';
import { LoadingPage } from './LoadingPage';
import { NotFoundPage } from './NotFoundPage';
import { PresentationPage } from './PresentationPage';
import { ResourcesPage } from './ResourcesPage';
import { SignInPage } from './SignInPage';
import { StudentNumberPage } from './StudentNumberPage';

const useGateObserver = withUseGateObserver(gate);

export const Router: React.FC = () => {
  const gateStatus = useGateObserver('Router');
  const storeStatus = useStore('Router');
  const { route, sub, id, setRedirect } = useNav();

  if (storeStatus.anyError) {
    return <ErrorPage error={storeStatus.anyError} />;
  }

  if (
    !gateStatus.ready ||
    !storeStatus.loadManifestCompleted ||
    storeStatus.updateCustomerInProgress
  ) {
    return <LoadingPage />;
  }

  if (route === '') {
    return <HomePage />;
  }

  if (route === 'goodbye') {
    return <GoodbyePage />;
  }

  if (route === 'legalpp') {
    return <LegalPpPage />;
  }

  if (route === 'legalts') {
    return <LegalTsPage />;
  }

  if (route === 'lectures' && sub) {
    return <ListMediaItemsPage kind="PRESENTATION" course={sub} />;
  }

  if (route === 'tutorials' && sub) {
    return <ListMediaItemsPage kind="PRESENTATION" course={sub} tutorial={true} />;
  }

  if (route === 'articles') {
    return <ListMediaItemsPage kind="ARTICLE" course={sub} />;
  }

  if (route === 'presentations') {
    return <ListMediaItemsPage kind="PRESENTATION" />;
  }

  if (route === 'article' && sub) {
    if (store.isOpen('ARTICLE', sub)) {
      return <ArticlePage mdKey={sub} />;
    } else if (gateStatus.hasAccess) {
      if (store.hasStudentNumber()) {
        return <ArticlePage mdKey={sub} />;
      } else if (store.actions.updateCustomer.totalCount > 0) {
        setRedirect({ route, sub });
        return <StudentNumberPage />;
      } else {
        return <LoadingPage />;
      }
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'presentation' && sub && id) {
    if (store.isOpen('PRESENTATION', sub)) {
      return <PresentationPage mdKey={sub} slideId={id} />;
    } else if (gateStatus.hasAccess) {
      if (store.hasStudentNumber()) {
        return <PresentationPage mdKey={sub} slideId={id} />;
      } else if (store.actions.updateCustomer.totalCount > 0) {
        return <StudentNumberPage />;
      } else {
        return <LoadingPage />;
      }
    } else {
      setRedirect({ route, sub, id });
      return <SignInPage />;
    }
  }

  if (route === 'resources' && sub) {
    if (gateStatus.hasAccess) {
      if (store.hasStudentNumber()) {
        return <ResourcesPage course={sub} />;
      } else if (store.actions.updateCustomer.totalCount > 0) {
        return <StudentNumberPage />;
      } else {
        return <LoadingPage />;
      }
    } else {
      setRedirect({ route, sub });
      return <SignInPage />;
    }
  }

  if (route === 'signin') {
    if (gateStatus.hasAccess) {
      return <HomePage signInSuccessful={true} />;
    }
    return <SignInPage />;
  }

  // waiting federation login
  if (
    window.location.hash === '#_=_' ||
    gateStatus.waitApple ||
    gateStatus.waitFacebook ||
    gateStatus.waitGoogle
  ) {
    return <LoadingPage />;
  }

  // not found
  return <NotFoundPage />;
};
